import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  approveOrder,
  getOrders,
  getSingleCustomer,
  getAllVendors,
} from "../../redux/thunk";
import toast from "react-hot-toast";
import LoadingSpinner from "../../components/elements/LoadingSpinner";
import OrderDetails from "../../components/modals/OrderDetails";
import axios from "axios";

import { RootState } from "../../redux/store";
import Pagination from "../../components/Pagination";

export const Order = () => {
  const [activeTab, setActiveTab] = useState("flexible");
  const [viewDetails, setViewDetails] = useState(false);
  const [users, setUsers] = useState<any>([]);
  const [orderDetails, setOrderDetails] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [pickupDetails, setPickupDetails] = useState<any>([]);
  const [filter, setFilter] = useState("");
  const [error, setError] = useState<any>({});
  const { token } = useAppSelector((store: RootState) => store.auth);

  const dispatch = useAppDispatch();
  const { orders } = useAppSelector((store) => store.orders);

  const [itemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastPost = currentPage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;

  useEffect(() => {
    setIsLoading((prev) => !prev);
    dispatch(getOrders()).then(() => {
      setIsLoading((prev) => !prev);
    });
    dispatch(getAllVendors());
  }, []);

  useEffect(() => {
    const extractedUserIds = orders.map((item: any) => item?.userId?.id);
    const fetchUsersData = async () => {
      const usersData = await Promise.all(
        extractedUserIds.map(async (id: any) => {
          const response = await dispatch(getSingleCustomer(id));

          return response.payload;
        })
      );
      setUsers(usersData);
    };

    if (extractedUserIds.length > 0) {
      fetchUsersData();
    }
  }, [dispatch, orders]);

  const validateOrderApproval = () => {
    let isValid = true;
    let newError: any = {};

    pickupDetails.forEach((item: any) => {
      if (item.vendorId.length === 0) {
        newError.pickupDetails = "Please select vendor";
        isValid = false;
      }
    });
    setError(newError);
    return isValid;
  };

  const handleInitialApprove = async (id: string, pickUpDetails: string) => {
    if (pickUpDetails.length === 0) {
      error.initialPickup = "Enter pickup location";
      return;
    }

    const payload = {
      orderId: id,
      pickUpDetails,
    };

    try {
      setIsLoading((prev) => !prev);
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/approveOrderInitial`,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      toast.success(res.data.message || "Order approved successfully");
      dispatch(getOrders());
      setIsLoading((prev) => !prev);
      setViewDetails((prev) => !prev);
    } catch (error: any) {
      toast.error(error.message || "Failed to approve order!");
    }
  };

  const handleApproveOrder = async (id: string) => {
    if (!validateOrderApproval()) {
      return;
    }

    try {
      setIsLoading((prev) => !prev);
      await dispatch(
        approveOrder({ orderId: id, pickUpDetails: pickupDetails })
      );

      dispatch(getOrders());
      setIsLoading((prev) => !prev);
      setViewDetails((prev) => !prev);
    } catch (error: any) {
      toast.error(error.message || "Failed to approve order!");
    }
  };

  const handleViewDetails = (orderData: any) => {
    setOrderDetails(orderData);
    setViewDetails((prev) => !prev);
  };

  const filteredOrders = orders.filter((order: any) => {
    const user = users.find(
      (user: any) => user?.customer?.id === order?.userId?.id
    );

    if (!user || !user.customer) {
      return false;
    }

    if (activeTab === "christmasPackage") {
      return order.isXmasPackage;
    } else if (activeTab === "flexible" && !order.isXmasPackage) {
      return user.customer.accountType === "flexible";
    } else if (activeTab === "outright" && !order.isXmasPackage) {
      return user.customer.accountType === "outright";
    }

    return false;
  });

  // pagination
  const reverseFilteredOrders = filteredOrders.slice().reverse();
  const currentItems = reverseFilteredOrders.slice(
    indexOfFirstPost,
    indexOfLastPost
  );

  const handlePagination = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const prevPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (
      currentPage !== Math.ceil(reverseFilteredOrders.length / itemsPerPage)
    ) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <main>
      <h2 className="font-semibold text-lg">Clients' Orders</h2>
      <div className=" my-6">
        <span
          className={`cursor-pointer mr-5 pb-2 ${
            activeTab === "flexible" ? "border-b-2 border-secondary" : "null"
          }`}
          onClick={() => setActiveTab("flexible")}
        >
          Flexible Orders
        </span>
        <span
          className={`cursor-pointer mr-5 pb-2 ${
            activeTab === "outright" ? "border-b-2 border-secondary" : "null"
          }`}
          onClick={() => setActiveTab("outright")}
        >
          Outright Orders
        </span>
        <span
          className={`cursor-pointer pb-2 ${
            activeTab === "christmasPackage"
              ? "border-b-2 border-secondary"
              : "null"
          }`}
          onClick={() => setActiveTab("christmasPackage")}
        >
          Christmas Package
        </span>
        <span className="float-right">
          <label htmlFor="filter">
            Sort:
            <select
              name="filter"
              id="filter"
              className="border p-2 ml-4 text-sm"
              onChange={(e) => setFilter(e.target.value)}
            >
              <option value="">All</option>
              <option value="pending">Pending</option>
              <option value="approved">Approved</option>
              <option value="delivered">Delivered</option>
              <option value="rider">Rider</option>
            </select>
          </label>
        </span>
      </div>
      <section className="w-full bg-white p-3">
        <table className="w-full">
          <thead className="text-left">
            <tr>
              <th>SN</th>
              <th>Client</th>
              {activeTab === "christmasPackage" && <th>Account Type</th>}
              <th>Action</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody className="relative">
            {isLoading ? (
              <tr>
                <td colSpan={4} className="mx-auto">
                  <LoadingSpinner />
                </td>
              </tr>
            ) : orders.length === 0 ? (
              <tr>
                <td className="flex items-end justify-end flex-col py-6">
                  <img
                    src="/assets/nothing.png"
                    className="w-[230px] h-[230px]"
                    alt=""
                  />
                </td>
              </tr>
            ) : (
              currentItems.length > 0 &&
              currentItems
                .filter((order: any) => {
                  if (filter === "pending") {
                    return order.status === "pending";
                  } else if (filter === "approved") {
                    return order.status === "approved";
                  } else if (filter === "delivered") {
                    return order.status === "delivered";
                  } else if (filter === "rider") {
                    return order.status === "rider";
                  } else {
                    return order;
                  }
                })
                .map((order: any, index: number) => (
                  <tr key={index}>
                    <td>{index + indexOfFirstPost + 1}</td>
                    <td>
                      {(() => {
                        const user = users.find(
                          (element: any) =>
                            element?.customer &&
                            order?.userId?.id === element.customer.id
                        );

                        return (
                          <span>
                            {user
                              ? `${user.customer.firstName} ${user.customer.lastName}`
                              : "Unknown User"}
                          </span>
                        );
                      })()}
                    </td>
                    <td className="capitalize">
                      {(() => {
                        const user = users.find(
                          (element: any) =>
                            element?.customer &&
                            order?.userId?.id === element.customer.id
                        );

                        return <span>{user && user.customer.accountType}</span>;
                      })()}
                    </td>
                    <td className="py-1">
                      <button
                        type="button"
                        className="p-2 bg-secondary rounded text-white text-sm"
                        onClick={() => handleViewDetails(order)}
                      >
                        View details
                      </button>
                    </td>
                    <td className="py-1">
                      <p
                        className={`p-2 w-fit rounded text-white text-sm ${
                          order.status === "approved"
                            ? "bg-secondary"
                            : "bg-yellow-300"
                        }`}
                      >
                        {order.status}
                      </p>
                    </td>
                  </tr>
                ))
            )}
          </tbody>
        </table>
        <section className="p-3 my-5">
          <Pagination
            length={filteredOrders.length}
            itemsPerPage={itemsPerPage}
            handlePagination={handlePagination}
            currentPage={currentPage}
            prevPage={prevPage}
            nextPage={nextPage}
          />
        </section>
      </section>

      {viewDetails && (
        <OrderDetails
          accountType={activeTab}
          orderDetails={orderDetails}
          setViewDetails={setViewDetails}
          pickupDetails={pickupDetails}
          setPickupDetails={setPickupDetails}
          handleApproveOrder={handleApproveOrder}
          handleInitialApprove={handleInitialApprove}
          error={error}
          setError={setError}
          isLoading={isLoading}
        />
      )}
    </main>
  );
};
