import { useEffect, useState } from "react";
import { useAppSelector } from "../../redux/hooks";
import { IoSearchOutline } from "react-icons/io5";
import LoadingSpinner from "../../components/elements/LoadingSpinner";
import axios from "axios";
import DetailedOrderModal from "../../components/modals/DetailedOrderModal";

const OrderHistory = () => {
  const { token } = useAppSelector((store) => store.auth);
  const [isLoading, setIsLoading] = useState(false);
  const [orderHistory, setOrderHistory] = useState([]);
  const [showHistory, setShowHistory] = useState(false);
  const [historyDetails, setHistoryDetails] = useState([]);

  useEffect(() => {
    const getAllOrderHistory = async () => {
      setIsLoading((prev) => !prev);
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/getAllOrderHistory`,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        setOrderHistory(res.data);
      } catch (error) {
        console.error("Failed to get:", error);
      } finally {
        setIsLoading(false);
      }
    };

    getAllOrderHistory();
  }, []);

  const handleViewHistory = (data: any) => {
    setShowHistory((prev) => !prev);
    setHistoryDetails(data);
  };

  return (
    <section>
      <h1 className="text-xl font-bold">Client Order History</h1>
      <section className="py-4 my-3 rounded-md bg-white">
        <div className="relative md:w-[30rem] my-4 float-right px-3 w-fit">
          <IoSearchOutline className="w-6 h-6 absolute top-[0.6rem] left-5 text-gray-300" />
          <input
            type="search"
            name="searchedUser"
            id="searchedUser"
            placeholder="Search user using email"
            className="border p-2 rounded-md indent-7 w-full"
          />
        </div>

        <table className="w-full text-left">
          <thead>
            <tr className="bg-gray-300 px-4">
              <th className="p-2">SN</th>
              <th className="p-2">Client</th>
              <th className="p-2">Email Address</th>
              <th className="p-2">Phone Number</th>
              <th className="p-2"></th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan={4} className="mx-auto">
                  <LoadingSpinner />
                </td>
              </tr>
            ) : (
              orderHistory.map((order: any, index: number) => (
                <tr key={index}>
                  <td className="px-2 py-3 border-b">{index + 1}</td>
                  <td className="px-2 py-3 border-b">
                    <span>{`${order.firstName} ${order.lastName}`}</span>
                  </td>
                  <td className="capitalize px-2 py-3 border-b">
                    <span>{order?.email}</span>
                  </td>
                  <td className="capitalize px-2 py-3 border-b">
                    <span>{order?.phoneNumber}</span>
                  </td>
                  <td className="px-2 py-3 border-b">
                    <button
                      type="button"
                      className="bg-secondary text-sm text-white p-2 rounded-md"
                      onClick={() => handleViewHistory(order.orders)}
                    >
                      View History
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </section>
      {showHistory && (
        <DetailedOrderModal
          closeModal={() => setShowHistory(false)}
          historyDetails={historyDetails}
        />
      )}
    </section>
  );
};

export default OrderHistory;
