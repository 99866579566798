import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SideBarProps } from "../types/types";
import { RxDashboard } from "react-icons/rx";
import {
  TbArrowsExchange,
  TbChristmasTree,
  TbListDetails,
  TbLogout2,
  TbTruckLoading,
} from "react-icons/tb";
import Back from "./Back";
import {
  FaHourglassStart,
  FaPersonCircleCheck,
  FaQuestion,
  FaRegNewspaper,
  FaUserDoctor,
  FaUsers,
} from "react-icons/fa6";
import {
  IoDocumentsOutline,
  IoPersonAddOutline,
  IoStorefrontOutline,
} from "react-icons/io5";
import {
  MdChecklist,
  MdCreditScore,
  MdDomainVerification,
  MdIncompleteCircle,
  MdOutlineAccountBalance,
  MdOutlineAssignment,
  MdOutlineDeliveryDining,
  MdOutlineHistory,
  MdOutlineMoney,
  MdOutlinePersonOff,
  MdOutlineRestaurantMenu,
  MdSchool,
} from "react-icons/md";
import { HiOutlineCommandLine } from "react-icons/hi2";
import { GoCrossReference, GoListOrdered, GoUnverified, GoVerified } from "react-icons/go";
import { RiCustomerService2Line } from "react-icons/ri";
import { BsPersonArmsUp } from "react-icons/bs";
import { BiMessageDetail } from "react-icons/bi";
import DietitianMessage from "./modals/DietitianMessage";
import ChangePassword from "./modals/ChangePassword";

const SideBar = ({
  tabNavigation,
  toggleTabNavigation,
  openModal,
  role,
}: SideBarProps) => {
  const navigate = useNavigate();
  const image: any = sessionStorage.getItem("image");
  const fullName = sessionStorage.getItem("name");
  const userEmail = sessionStorage.getItem("userEmail");
  const adminLevel = sessionStorage.getItem("adminLevel");
  const [showMessageBox, setShowMessageBox] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [clientName, setClientName] = useState("");
  const role2 = "dietitia";

  const clientMsg = [
    {
      name: "Jenifer o",
      img: "/assets/picture.jpg",
      request: [],
    },
    {
      name: "Blessing o",
      img: "/assets/picture.jpg",
      request: [],
    },
    {
      name: "Ella P",
      img: "/assets/picture.jpg",
      request: [],
    },
    {
      name: "Grace P",
      img: "/assets/picture.jpg",
      request: [],
    },
    {
      name: "Motunrayo K",
      img: "/assets/picture.jpg",
      request: [],
    },
    {
      name: "Zen Ah",
      img: "/assets/picture.jpg",
      request: [],
    },
    {
      name: "Winnie D",
      img: "/assets/picture.jpg",
      request: [],
    },
    {
      name: "Feranmi J",
      img: "/assets/picture.jpg",
      request: [],
    },
  ];

  const tabs: any = [
    {
      title: (
        <div className="flex items-center">
          <RxDashboard className="w-6 h-6" />
          <span className="pl-2">Dashboard</span>
        </div>
      ),
      query: "dashboard",
    },
    adminLevel === "superadmin"
      ? {
          title: (
            <div className="flex items-center">
              <FaRegNewspaper className="w-6 h-6" />
              <span className="pl-2">News Update</span>
            </div>
          ),
          query: "dashboard/news-update",
        }
      : role && role.some((data: any) => data.role === "News update")
      ? {
          title: (
            <div className="flex items-center">
              <FaRegNewspaper className="w-6 h-6" />
              <span className="pl-2">News Update</span>
            </div>
          ),
          query: "dashboard/news-update",
        }
      : {},
    adminLevel === "superadmin"
      ? {
          title: (
            <div className="flex items-center">
              <TbChristmasTree className="w-6 h-6" />
              <span className="pl-2">Christmas Package</span>
            </div>
          ),
          query: "dashboard/christmas-package",
        }
      : role && role.some((data: any) => data.role === "Christmas Package")
      ? {
          title: (
            <div className="flex items-center">
              <TbChristmasTree className="w-6 h-6" />
              <span className="pl-2">Christmas Package</span>
            </div>
          ),
          query: "dashboard/christmas-package",
        }
      : {},
    adminLevel === "superadmin"
      ? {
          title: (
            <div className="flex items-center">
              <IoStorefrontOutline className="w-6 h-6" />
              <span className="pl-2">Store</span>
            </div>
          ),
          query: "dashboard/store",
        }
      : role && role.some((data: any) => data.role === "Store")
      ? {
          title: (
            <div className="flex items-center">
              <IoStorefrontOutline className="w-6 h-6" />
              <span className="pl-2">Store</span>
            </div>
          ),
          query: "dashboard/store",
        }
      : {},
    adminLevel === "superadmin"
      ? {
          title: (
            <div className="flex items-center">
              <MdOutlineDeliveryDining className="w-6 h-6" />
              <span className="pl-2">Logistics</span>
            </div>
          ),
          query: "dashboard/logistics",
        }
      : role && role.some((data: any) => data.role === "Logistics")
      ? {
          title: (
            <div className="flex items-center">
              <MdOutlineDeliveryDining className="w-6 h-6" />
              <span className="pl-2">Logistics</span>
            </div>
          ),
          query: "dashboard/logistics",
        }
      : {},
    adminLevel === "superadmin"
      ? {
          title: (
            <div className="flex items-center">
              <MdOutlineRestaurantMenu className="w-6 h-6" />
              <span className="pl-2">Restaurants</span>
            </div>
          ),
          query: "dashboard/restaurants",
        }
      : role && role.some((data: any) => data.role === "Restaurants")
      ? {
          title: (
            <div className="flex items-center">
              <MdOutlineRestaurantMenu className="w-6 h-6" />
              <span className="pl-2">Restaurants</span>
            </div>
          ),
          query: "dashboard/restaurants",
        }
      : {},
      adminLevel === "superadmin"
      ? {
          title: (
            <div className="flex items-center">
              <GoCrossReference className="w-6 h-6"/>
              <span className="pl-2">Referrals</span>
            </div>
          ),
          query: "dashboard/referrals",
        }
      : role && role.some((data: any) => data.role === "Referrals")
      ? {
          title: (
            <div className="flex items-center">
              <GoCrossReference className="w-6 h-6" />
              <span className="pl-2">Referrals</span>
            </div>
          ),
          query: "dashboard/referrals",
        }
      : {},
    adminLevel === "superadmin"
      ? {
          title: (
            <div className="flex items-center">
              <FaUserDoctor className="w-6 h-6" />
              <span className="pl-2">Dietitians</span>
            </div>
          ),
          query: "dashboard/dietitians",
        }
      : role && role.some((data: any) => data.role === "Dietitians")
      ? {
          title: (
            <div className="flex items-center">
              <FaUserDoctor className="w-6 h-6" />
              <span className="pl-2">Dietitians</span>
            </div>
          ),
          query: "dashboard/dietitians",
        }
      : {},
    adminLevel === "superadmin"
      ? {
          title: (
            <div className="flex items-center">
              <BsPersonArmsUp className="w-6 h-6" />
              <span className="pl-2">Vendors</span>
            </div>
          ),
          query: "dashboard/vendors",
        }
      : role && role.some((data: any) => data.role === "Vendors")
      ? {
          title: (
            <div className="flex items-center">
              <BsPersonArmsUp className="w-6 h-6" />
              <span className="pl-2">Vendors</span>
            </div>
          ),
          query: "dashboard/vendors",
        }
      : {},
    adminLevel === "superadmin"
      ? {
          title: (
            <div className="flex items-center">
              <FaPersonCircleCheck className="w-6 h-6" />
              <span className="pl-2">Staff</span>
            </div>
          ),
          query: "dashboard/staff",
        }
      : role && role.some((data: any) => data.role === "Staff")
      ? {
          title: (
            <div className="flex items-center">
              <FaPersonCircleCheck className="w-6 h-6" />
              <span className="pl-2">Staff</span>
            </div>
          ),
          query: "dashboard/staff",
        }
      : {},
    adminLevel === "superadmin"
      ? {
          title: (
            <div className="flex items-center">
              <TbTruckLoading className="w-6 h-6" />
              <span className="pl-2">Operations</span>
            </div>
          ),
          query: "dashboard/operations",
        }
      : role && role.some((data: any) => data.role === "Operations")
      ? {
          title: (
            <div className="flex items-center">
              <TbTruckLoading className="w-6 h-6" />
              <span className="pl-2">Operations</span>
            </div>
          ),
          query: "dashboard/operations",
        }
      : {},
    adminLevel === "superadmin"
      ? {
          title: (
            <div className="flex items-center">
              <MdOutlineAssignment className="w-6 h-6" />
              <span className="pl-2">Assignment</span>
            </div>
          ),
          query: "dashboard/assignment",
        }
      : {},
    adminLevel === "superadmin"
      ? {
          title: (
            <div className="flex items-center">
              <MdOutlineAccountBalance className="w-6 h-6" />
              <span className="pl-2">Virtual Account</span>
            </div>
          ),
          query: "dashboard/virtual-account",
        }
      : role && role.some((data: any) => data.role === "Virtual Account")
      ? {
          title: (
            <div className="flex items-center">
              <MdOutlineAccountBalance className="w-6 h-6" />
              <span className="pl-2">Virtual Account</span>
            </div>
          ),
          query: "dashboard/virtual-account",
        }
      : {},
    adminLevel === "superadmin"
      ? {
          title: (
            <div className="flex items-center">
              <GoUnverified className="w-6 h-6" />
              <span className="pl-2"> E-Verification 1</span>
            </div>
          ),
          query: "dashboard/employed-verification1",
        }
      : role && role.some((data: any) => data.role === "E-Verification 1")
      ? {
          title: (
            <div className="flex items-center">
              <GoUnverified className="w-6 h-6" />
              <span className="pl-2"> E-Verification 1</span>
            </div>
          ),
          query: "dashboard/employed-verification1",
        }
      : {},
    adminLevel === "superadmin"
      ? {
          title: (
            <div className="flex items-center">
              <GoVerified className="w-6 h-6" />
              <span className="pl-2"> SE-Verification 1</span>
            </div>
          ),
          query: "dashboard/self-employed-verification1",
        }
      : role && role.some((data: any) => data.role === "SE-Verification 1")
      ? {
          title: (
            <div className="flex items-center">
              <GoVerified className="w-6 h-6" />
              <span className="pl-2"> SE-Verification 1</span>
            </div>
          ),
          query: "dashboard/self-employed-verification1",
        }
      : {},
    adminLevel === "superadmin"
      ? {
          title: (
            <div className="flex items-center">
              <MdDomainVerification className="w-6 h-6" />
              <span className="pl-2"> S-Verification 1</span>
            </div>
          ),
          query: "dashboard/student-verification1",
        }
      : role && role.some((data: any) => data.role === "S-Verification 1")
      ? {
          title: (
            <div className="flex items-center">
              <MdDomainVerification className="w-6 h-6" />
              <span className="pl-2"> S-Verification 1</span>
            </div>
          ),
          query: "dashboard/student-verification1",
        }
      : {},
    adminLevel === "superadmin"
      ? {
          title: (
            <div className="flex items-center">
              <IoDocumentsOutline className="w-6 h-6" />
              <span className="pl-2">Document Check</span>
            </div>
          ),
          query: "dashboard/document-check",
        }
      : role && role.some((data: any) => data.role === "Document Check")
      ? {
          title: (
            <div className="flex items-center">
              <IoDocumentsOutline className="w-6 h-6" />
              <span className="pl-2">Document Check</span>
            </div>
          ),
          query: "dashboard/document-check",
        }
      : {},
    adminLevel === "superadmin"
      ? {
          title: (
            <div className="flex items-center">
              <MdCreditScore className="w-6 h-6" />
              <span className="pl-2">Credit Score</span>
            </div>
          ),
          query: "dashboard/credit-score",
        }
      : role && role.some((data: any) => data.role === "Credit Score")
      ? {
          title: (
            <div className="flex items-center">
              <MdCreditScore className="w-6 h-6" />
              <span className="pl-2">Credit Score</span>
            </div>
          ),
          query: "dashboard/credit-score",
        }
      : {},
    adminLevel === "superadmin"
      ? {
          title: (
            <div className="flex items-center">
              <MdSchool className="w-6 h-6" />
              <span className="pl-2">Institute Upload</span>
            </div>
          ),
          query: "dashboard/institute-upload",
        }
      : role && role.some((data: any) => data.role === "Institute Upload")
      ? {
          title: (
            <div className="flex items-center">
              <MdSchool className="w-6 h-6" />
              <span className="pl-2">Institute Upload</span>
            </div>
          ),
          query: "dashboard/institute-upload",
        }
      : {},
    adminLevel === "superadmin"
      ? {
          title: (
            <div className="flex items-center">
              <MdOutlineMoney className="w-6 h-6" />
              <span className="pl-2">Next Pay</span>
            </div>
          ),
          query: "dashboard/next-pay",
        }
      : role && role.some((data: any) => data.role === "Next Pay")
      ? {
          title: (
            <div className="flex items-center">
              <MdOutlineMoney className="w-6 h-6" />
              <span className="pl-2">Next Pay</span>
            </div>
          ),
          query: "dashboard/next-pay",
        }
      : {},
    adminLevel === "superadmin"
      ? {
          title: (
            <div className="flex items-center">
              <HiOutlineCommandLine className="w-6 h-6" />
              <span className="pl-2">E-mandate</span>
            </div>
          ),
          query: "dashboard/e-mandate",
        }
      : role && role.some((data: any) => data.role === "E-mandate")
      ? {
          title: (
            <div className="flex items-center">
              <HiOutlineCommandLine className="w-6 h-6" />
              <span className="pl-2">E-mandate</span>
            </div>
          ),
          query: "dashboard/e-mandate",
        }
      : {},
    adminLevel === "superadmin"
      ? {
          title: (
            <div className="flex items-center">
              <FaHourglassStart className="w-5 h-5" />
              <span className="pl-2">Initiate Mandate</span>
            </div>
          ),
          query: "dashboard/pending-emandate",
        }
      : role && role.some((data: any) => data.role === "Initiate Mandate")
      ? {
          title: (
            <div className="flex items-center">
              <FaHourglassStart className="w-5 h-5" />
              <span className="pl-2">Initiate Mandate</span>
            </div>
          ),
          query: "dashboard/pending-emandate",
        }
      : {},
    adminLevel === "superadmin"
      ? {
          title: (
            <div className="flex items-center">
              <MdIncompleteCircle className="w-6 h-6" />
              <span className="pl-2">Incomplete Users</span>
            </div>
          ),
          query: "dashboard/incomplete-users",
        }
      : role && role.some((data: any) => data.role === "Incomplete Users")
      ? {
          title: (
            <div className="flex items-center">
              <MdIncompleteCircle className="w-6 h-6" />
              <span className="pl-2">Incomplete Users</span>
            </div>
          ),
          query: "dashboard/incomplete-users",
        }
      : {},
    adminLevel === "superadmin"
      ? {
          title: (
            <div className="flex items-center">
              <TbListDetails className="w-6 h-6" />
              <span className="pl-2">User Details</span>
            </div>
          ),
          query: "dashboard/user-details",
        }
      : role && role.some((data: any) => data.role === "User Details")
      ? {
          title: (
            <div className="flex items-center">
              <TbListDetails className="w-6 h-6" />
              <span className="pl-2">User Details</span>
            </div>
          ),
          query: "dashboard/user-details",
        }
      : {},
    adminLevel === "superadmin"
      ? {
          title: (
            <div className="flex items-center">
              <FaUsers className="w-6 h-6" />
              <span className="pl-2">Users</span>
            </div>
          ),
          query: "dashboard/users",
        }
      : role && role.some((data: any) => data.role === "Users")
      ? {
          title: (
            <div className="flex items-center">
              <FaUsers className="w-6 h-6" />
              <span className="pl-2">Users</span>
            </div>
          ),
          query: "dashboard/users",
        }
      : {},
    adminLevel === "superadmin"
      ? {
          title: (
            <div className="flex items-center">
              <MdChecklist className="w-6 h-6" />
              <span className="pl-2">Orders</span>
            </div>
          ),
          query: "dashboard/order-requests",
        }
      : role && role.some((data: any) => data.role === "Orders")
      ? {
          title: (
            <div className="flex items-center">
              <MdChecklist className="w-6 h-6" />
              <span className="pl-2">Orders</span>
            </div>
          ),
          query: "dashboard/order-requests",
        }
      : {},
    adminLevel === "superadmin"
      ? {
          title: (
            <div className="flex items-center">
              <GoListOrdered className="w-6 h-6"/>
              <span className="pl-2">Order History</span>
            </div>
          ),
          query: "dashboard/order-history",
        }
      : role && role.some((data: any) => data.role === "Order History")
      ? {
          title: (
            <div className="flex items-center">
              <GoListOrdered className="w-6 h-6"/>
              <span className="pl-2">Order History</span>
            </div>
          ),
          query: "dashboard/order-history",
        }
      : {},
    adminLevel === "superadmin"
      ? {
          title: (
            <div className="flex items-center">
              <MdOutlinePersonOff className="w-6 h-6" />
              <span className="pl-2">Defaulters</span>
            </div>
          ),
          query: "dashboard/defaulters",
        }
      : role && role.some((data: any) => data.role === "Defaulters")
      ? {
          title: (
            <div className="flex items-center">
              <MdOutlinePersonOff className="w-6 h-6" />
              <span className="pl-2">Defaulters</span>
            </div>
          ),
          query: "dashboard/defaulters",
        }
      : {},
    adminLevel === "superadmin"
      ? {
          title: (
            <div className="flex items-center">
              <IoPersonAddOutline className="w-6 h-6" />
              <span className="pl-2">Create Admin</span>
            </div>
          ),

          query: "dashboard/create-admin",
        }
      : role && role.some((data: any) => data.role === "Create Admin")
      ? {
          title: (
            <div className="flex items-center">
              <IoPersonAddOutline className="w-6 h-6" />
              <span className="pl-2">Create Admin</span>
            </div>
          ),

          query: "dashboard/create-admin",
        }
      : {},
    adminLevel === "superadmin"
      ? {
          title: (
            <div className="flex items-center">
              <RiCustomerService2Line className="w-6 h-6" />
              <span className="pl-2">Customer Care</span>
            </div>
          ),
          query: "dashboard/customer-care",
        }
      : role && role.some((data: any) => data.role === "Customer Care")
      ? {
          title: (
            <div className="flex items-center">
              <RiCustomerService2Line className="w-6 h-6" />
              <span className="pl-2">Customer Care</span>
            </div>
          ),
          query: "dashboard/customer-care",
        }
      : {},
    adminLevel === "superadmin"
      ? {
          title: (
            <div className="flex items-center">
              <FaQuestion className="w-6 h-6" />
              <span className="pl-2">FAQ</span>
            </div>
          ),
          query: "dashboard/faq",
        }
      : role && role.some((data: any) => data.role === "FAQ")
      ? {
          title: (
            <div className="flex items-center">
              <FaQuestion className="w-6 h-6" />
              <span className="pl-2">FAQ</span>
            </div>
          ),
          query: "dashboard/faq",
        }
      : {},
    adminLevel === "superadmin"
      ? {
          title: (
            <div className="flex items-center">
              <MdOutlineHistory className="w-6 h-6" />
              <span className="pl-2">Repayment History</span>
            </div>
          ),
          query: "dashboard/repayment-history",
        }
      : role && role.some((data: any) => data.role === "Repayment History")
      ? {
          title: (
            <div className="flex items-center">
              <MdOutlineHistory className="w-6 h-6" />
              <span className="pl-2">Repayment History</span>
            </div>
          ),
          query: "dashboard/repayment-history",
        }
      : {},
  ];

  const [currentTab, setCurrentTab] = useState(
    window.location.pathname.replace("/", "") || "dashboard"
  );

  const handleOverlayClick = () => {
    toggleTabNavigation();
  };

  const handleTabChange = (tab: any) => {
    setCurrentTab(tab);
    navigate(`/${tab}`);
    toggleTabNavigation();
  };

  const handleClickMessage = (name: string) => {
    setShowMessageBox(true);
    setClientName(name);
  };

  return (
    <>
      <div
        className={`fixed hidden top-0 h-screen inset-0 bg-black  opacity-50 z-40 ${
          tabNavigation ? "block" : "hidden"
        }`}
        onClick={handleOverlayClick}
      ></div>
      <ul
        className={`w-[70%] lg:w-[20%] overflow-y-auto shadow-md fixed bg-white p-4 h-full ${
          !tabNavigation
            ? "hidden lg:block"
            : "flex flex-col sm:items-center justify-between z-50 bg-white text-black h-full animate-fadeIn"
        }`}
      >
        <div className="w-full mb-auto flex flex-col justify-between h-full">
          <div>
            {role2 === "dietitian".toLowerCase() ? (
              <div className="flex gap-2 items-center pb-2 mb-2 justify-center">
                <FaUsers className="w-8 h-8" />
                <p className="font-semibold text-2xl">User Count</p>
              </div>
            ) : (
              <div className="flex items-center justify-between mb-[3rem] p-2  ">
                <img
                  src="/assets/logo.png"
                  alt="logo"
                  className=" self-center w-[6rem] "
                />
                <Back />
              </div>
            )}
            {role2 === "dietitian".toLowerCase()
              ? clientMsg.map((item, index) => (
                  <div
                    key={index}
                    className="p-2 flex items-center border border-white justify-between
                     cursor-pointer text-secondary hover:bg-secondary hover:text-white"
                    onClick={() => handleClickMessage(item.name)}
                  >
                    <img
                      src={item.img}
                      className="w-12 h-12 rounded-full"
                      alt={item.name}
                    />
                    <p className=" font-semibold">{item.name}</p>
                    <BiMessageDetail className="w-6 h-6" />
                  </div>
                ))
              : tabs.map((tab: any, index: Number) =>
                  !tab || !Object.keys(tab).length ? (
                    <React.Fragment key={`${index}`}></React.Fragment>
                  ) : (
                    <div
                      key={tab.query}
                      className="pb-2"
                      onClick={() => handleTabChange(tab.query)}
                    >
                      <li
                        className={`text-xs cursor-pointer ${
                          tab.query === currentTab
                            ? "border border-secondary200 bg-secondary100 text-secondary w-full py-1 rounded-md"
                            : ""
                        } p-2`}
                      >
                        {tab.title}
                      </li>
                    </div>
                  )
                )}
          </div>
          {role2 === "dietitian".toLowerCase() && (
            <section className="bg-gradient-to-r from-secondary to-[#92E3A9] text-white p-3 my-3 text-center rounded-3xl">
              <p className="text-sm w-2/3 mx-auto">Contact Customer Care</p>
              <button
                type="button"
                className="text-secondary w-11/12 my-2 text-sm rounded-full  bg-white p-1"
              >
                Contact Now
              </button>
            </section>
          )}
          <div className="mt-auto flex flex-col mb-4">
            <div
              onClick={() => setShowChangePassword((prev) => !prev)}
              className="flex items-center mb-4 gap-2 rounded-md text-sm font-semibold py-3 px-1 cursor-pointer border-2"
            >
              <TbArrowsExchange className="w-6 h-6" />
              <p>Change Password</p>
            </div>
            <div
              onClick={openModal}
              className="bg-[#ECE9F1] flex items-center mb-4 rounded-md text-sm py-3 px-1 cursor-pointer border-2 "
            >
              <TbLogout2 className="w-6 h-6" />
              <span className="pl-4">Log Out</span>
            </div>
            <div className="flex items-start flex-col xl:flex-row">
              <div>
                <h1 className="font-semibold capitalize">{fullName}</h1>
                <p className="text-gray-600 text-sm">{userEmail}</p>
              </div>
            </div>
          </div>
        </div>
      </ul>
      {showMessageBox && (
        <DietitianMessage
          setShowMessageBox={setShowMessageBox}
          clientName={clientName}
        />
      )}
      {showChangePassword && (
        <ChangePassword setShowChangePassword={setShowChangePassword} />
      )}
    </>
  );
};

export default SideBar;
