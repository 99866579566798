import { useEffect, useState } from "react";
import Modal, { ModalContent } from "./elements/Modal";
import { MdOutlineCancel } from "react-icons/md";
import { formatNumber } from "../redux/thunk";

const RepaymentModal = ({ repaymentDetails, closeModal }: any) => {
  const [totalLoan, setTotalLoan] = useState(0);

  useEffect(() => {
    let totalLoanCount = repaymentDetails[0].totalLoan;

    for (let i = 1; i < repaymentDetails.length; i++) {
      if (repaymentDetails[i - 1].amountLeft === 0) {
        totalLoanCount += repaymentDetails[i].totalLoan;
      }
    }
    setTotalLoan(totalLoanCount);
  }, [repaymentDetails]);

  return (
    <Modal
      open={true}
      onClose={closeModal}
      className="flex items-center justify-center"
    >
      <ModalContent className="mx-3 p-6 rounded-md shadow-lg flex flex-col justify-center items-center bg-white w-[80%] md:w-[55%] lg:w-[50%] xl:w-[30%] ">
        <section className="w-full h-96 overflow-y-scroll p-2">
          <div className="flex justify-end">
            <MdOutlineCancel
              className="text-2xl cursor-pointer"
              onClick={closeModal}
            />
          </div>
          <h3 className="font-bold text-xl text-secondary mt-2">Total Loan</h3>
          <p>{formatNumber(totalLoan)}</p>

          <section className="flex justify-between items-center my-2 border-b-2 py-3">
            <div className="border-r-2 w-1/2">
              <h3 className="font-bold text-xl text-secondary">Current Loan</h3>
              <p>
                {formatNumber(
                  repaymentDetails[repaymentDetails.length - 1].totalLoan
                )}
              </p>
            </div>
            <div>
              <h3 className="font-bold text-xl text-secondary">Current Left</h3>
              <p>
                {formatNumber(
                  repaymentDetails[repaymentDetails.length - 1].amountLeft
                )}
              </p>
            </div>
          </section>

          <section>
            <h3 className="font-semibold text-secondary">
              Loan Details
            </h3>
            {repaymentDetails
              .slice()
              .reverse()
              .map((details: any, index: number) => (
                <section
                  className=" border shadow-md p-3 my-2 text-sm rounded-lg"
                  key={index}
                >
                  <div className="bg-secondary text-center text-xs w-5 h-5 rounded-[50%] text-white mb-2">
                    {repaymentDetails.length - index}
                  </div>
                  <h3 className="font-medium">
                    Total Loan: {formatNumber(details.totalLoan)}
                  </h3>
                  <h3 className="font-medium">
                    Amount Paid: {formatNumber(details.paidLoan)}
                  </h3>
                  <h3 className="font-medium">
                    Date paid:{" "}
                    {new Date(details.payDate).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    })}
                  </h3>
                  <h3 className="font-medium">
                    Amount Left: {formatNumber(details.amountLeft)}
                  </h3>
                </section>
              ))}
          </section>
        </section>
      </ModalContent>
    </Modal>
  );
};

export default RepaymentModal;
