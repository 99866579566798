import { createSlice } from "@reduxjs/toolkit";
import {
  getAllIncompleteUsers,
  getFlexibleUsers,
  getOutrightUsers,
} from "../thunk";

interface UsersState {
  flexible: any;
  outright: any;
  incomplete: any;
  deactivated: any;
  status: string;
  error: string;
}

const initialState: UsersState = {
  flexible: [],
  outright: [],
  incomplete: [],
  deactivated: [],
  status: "idle",
  error: "",
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    getDeactivated: (state) => {
      state.deactivated = state.incomplete.filter((user: any) => user.blocked);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFlexibleUsers.pending, (state) => {
        state.status = "loading";
        state.error = "nil";
      })
      .addCase(getFlexibleUsers.fulfilled, (state, action) => {
        state.status = "success";
        state.flexible = action.payload.data;
        state.error = "nil";
      })
      .addCase(getFlexibleUsers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error?.message || "Unknown error";
      })

      .addCase(getOutrightUsers.pending, (state) => {
        state.status = "loading";
        state.error = "nil";
      })
      .addCase(getOutrightUsers.fulfilled, (state, action) => {
        state.status = "success";
        state.outright = action.payload;
        state.error = "nil";
      })
      .addCase(getOutrightUsers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error?.message || "Unknown error";
      })
      .addCase(getAllIncompleteUsers.pending, (state) => {
        state.status = "loading";
        state.error = "nil";
      })
      .addCase(getAllIncompleteUsers.fulfilled, (state, action) => {
        state.status = "success";
        state.incomplete = action.payload;
        state.error = "nil";
      })
      .addCase(getAllIncompleteUsers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error?.message || "Unknown error";
      });
  },
});

export const { getDeactivated } = usersSlice.actions;
export default usersSlice.reducer;
