import { useEffect, useState } from "react";
import NotificationCard from "../components/NotificationCard";
import { IoMdClose } from "react-icons/io";

const Notification = () => {
  const [activeTab, setActiveTab] = useState("all");
  const [filteredNotification, setFilteredNotification] = useState<
    {
      name: string;
      img: string;
      msg: string;
      details: string;
      dateTime: string;
      read: boolean;
      tag: string;
    }[]
  >([]);
  const [notifications, setNotifications] = useState<
    {
      name: string;
      img: string;
      msg: string;
      details: string;
      dateTime: string;
      read: boolean;
      tag: string;
    }[]
  >([
    {
      name: "Patrick",
      img: "/assets/Avatar.png",
      msg: "Patrick Just Signed-Up",
      details: "Email Verification is pending",
      dateTime: "15h",
      read: false,
      tag: "signUp",
    },
    {
      name: "Patrick",
      img: "/assets/Avatar.png",
      msg: "Patrick Just Signed-Up",
      details: "Email Verification is pending",
      dateTime: "15h",
      read: false,
      tag: "signUp",
    },
    {
      name: "Fikayo",
      img: "/assets/Avatar.png",
      msg: "Admin Fikayo Has approved New User",
      details: "",
      dateTime: "15h",
      read: false,
      tag: "adminActivities",
    },
    {
      name: "",
      img: "/assets/Avatar.png",
      msg: "18 Orders have been approved",
      details: "",
      dateTime: "15h",
      read: false,
      tag: "orders",
    },
    {
      name: "Fikayo",
      img: "/assets/Avatar.png",
      msg: "Admin Fikayo Has approved New User",
      details: "",
      dateTime: "15h",
      read: false,
      tag: "adminActivities",
    },
    {
      name: "",
      img: "/assets/Avatar.png",
      msg: "18 Orders have been approved",
      details: "",
      dateTime: "15h",
      read: false,
      tag: "orders",
    },
  ]);

  const [activeNotificationId, setActiveNotificationId] = useState<
    null | number
  >(null);

  const handleMoreClick = (index: number) => {
    setActiveNotificationId((prev) => (prev === index ? null : index));
  };

  const handleOutsideClick = () => {
    setActiveNotificationId(null);
  };

  useEffect(() => {
    const filterNotifications = () => {
      if (activeTab === "all") {
        setFilteredNotification(notifications);
      } else {
        const filtered = notifications.filter((item) => item.tag === activeTab);
        setFilteredNotification(filtered);
      }
    };
    filterNotifications();
  }, [activeTab, notifications]);

  const handleMarkAsRead = (index: number) => {
    const updatedNotifications = [...notifications];
    updatedNotifications[index].read = true;
    setNotifications(updatedNotifications);
  };

  const handleMarkAsUnread = (index: number) => {
    const updatedNotifications = [...notifications];
    updatedNotifications[index].read = false;
    setNotifications(updatedNotifications);
  };

  const markAllAsRead = () => {
    const updatedNotifications = notifications.map((notification) => ({
      ...notification,
      read: true,
    }));
    setNotifications(updatedNotifications);
  };

  return (
    <section className="bg-white py-5 px-7" onClick={()=>setActiveNotificationId(null)}>
      <div className="flex items-center justify-between px-4">
        <h3 className="font-bold text-lg">Notifications</h3>
        <button
          type="button"
          className="text-secondary text-sm flex items-center gap-3 cursor-pointer"
          onClick={() => markAllAsRead()}
        >
          Mark all as read
          <span>
            <IoMdClose className="w-5 h-5 text-gray-400" />
          </span>
        </button>
      </div>
      <section className="py-3 border-b">
        <ul className="flex items-center gap-6">
          <li
            className={`${
              activeTab === "all" && "border-b-2 border-secondary"
            } px-4 py-1 text-sm cursor-pointer`}
            onClick={() => setActiveTab("all")}
          >
            All
          </li>
          <li
            className={`${
              activeTab === "signUp" && "border-b-2 border-secondary"
            } px-4 py-1 text-sm cursor-pointer`}
            onClick={() => setActiveTab("signUp")}
          >
            Sign-up
          </li>
          <li
            className={`${
              activeTab === "admin" && "border-b-2 border-secondary"
            } px-4 py-1 text-sm cursor-pointer`}
            onClick={() => setActiveTab("adminActivities")}
          >
            Admin Activities
          </li>
          <li
            className={`${
              activeTab === "orders" && "border-b-2 border-secondary"
            } px-4 py-1 text-sm cursor-pointer`}
            onClick={() => setActiveTab("orders")}
          >
            Orders
          </li>
        </ul>
      </section>
      <section>
        {notifications.length > 0 ? (
          filteredNotification.map((item: any, index: number) => (
            <NotificationCard
              key={index}
              index={index}
              img={item.img}
              name={item.name}
              msg={item.msg}
              details={item.details}
              dateTime={item.dateTime}
              readStatus={item.read}
              setReadMsg={() => handleMarkAsRead(index)}
              setUnreadMsg={() => handleMarkAsUnread(index)}
              activeNotificationId={activeNotificationId}
              setActiveNotificationId={setActiveNotificationId}
            />
          ))
        ) : (
          <p className="text-secondary text-center py-20">No Notifications</p>
        )}
      </section>
    </section>
  );
};

export default Notification;
