import React, { useEffect, useState } from "react";
import { IoSearchOutline } from "react-icons/io5";
import { formatNumber } from "../../redux/thunk";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAppSelector } from "../../redux/hooks";
import LoadingSpinner from "../../components/elements/LoadingSpinner";
import Pagination from "../../components/Pagination";

const ReferralIndex = () => {
  const { token } = useAppSelector((store) => store.auth);
  const [filter, setFilter] = useState("");
  const [isActive, setIsActive] = useState("newest");
  const [isLoading, setIsLoading] = useState(false);
  const [referralData, setReferralData] = useState([]);
  const [totalReferred, setTotalReferred] = useState(0);
  const [totalComplete, setTotalComplete] = useState(0);
  const [totalPending, setTotalPending] = useState(0);
  const [searchedUser, setSearchedUser] = useState<any>([]);
  const [searchResults, setSearchResults] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const getReferralStats = async () => {
      setIsLoading(true);
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/getReferralStatistics`,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        setReferralData(res.data);
      } catch (error) {
        console.error("Failed to fetch:", error);
      } finally {
        setIsLoading(false);
      }
    };

    getReferralStats();
  }, []);

  useEffect(() => {
    let totalCount = 0;
    let completeCount = 0;
    let pendingCount = 0;

    if (referralData && referralData.length > 0) {
      referralData.forEach((item: any) => {
        totalCount += item.totalReferredCustomers;
        completeCount += item.totalReferredWithOrders;
        pendingCount += item.totalReferredWithoutOrders;
      });
    }

    setTotalReferred(totalCount);
    setTotalComplete(completeCount);
    setTotalPending(pendingCount);
  }, [referralData]);

  const searchForUsersWithAccountName = (value: string) => {
    setSearchedUser(value);
    if (value.length > 0) {
      const searchResult =
        referralData?.filter((user: any) =>
          user.customerId?.toLowerCase().includes(value.toLowerCase())
        ) || null;
      setSearchResults(searchResult);
    } else {
      setSearchResults([]);
    }
  };

  const [itemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastPost = currentPage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;
  const currentItems = referralData.slice(indexOfFirstPost, indexOfLastPost);

  // Pagination
  const handlePagination = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const prevPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage !== Math.ceil(data.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const data =
    searchedUser && searchedUser.length > 0 ? searchResults : referralData;

  return (
    <main>
      <section className="flex my-5 flex-wrap gap-8">
        <div
          className={`bg-white p-3 rounded-md shadow-md w-48 ${
            isLoading && "animate-pulse "
          }`}
        >
          <p className="font-medium pb-2 text-gray-700 caption-bottom">
            {formatNumber(totalReferred)}
          </p>
          <h3 className="font-semibold text-sm text-[#8F7803]">
            Total Referrals
          </h3>
        </div>
        <div
          className={`bg-white p-3 rounded-md shadow-md w-48 ${
            isLoading && "animate-pulse "
          }`}
        >
          <p className="font-medium pb-2 text-gray-700 caption-bottom">
            {formatNumber(totalComplete)}
          </p>
          <h3 className="font-semibold text-sm text-secondary">
            Complete Referrals
          </h3>
        </div>
        <div
          className={`bg-white p-3 rounded-md shadow-md w-48 ${
            isLoading && "animate-pulse "
          }`}
        >
          <p className="font-medium pb-2 text-gray-700 caption-bottom">
            {formatNumber(totalPending)}
          </p>
          <h3 className="font-semibold text-sm text-[#F59040]">
            Pending Referrals
          </h3>
        </div>
      </section>

      <section className="my-10">
        <h3 className="font-bold text-lg">Referrals</h3>
        <section className="flex justify-between items-center pb-4">
          <h3 className="text-secondary text-sm">Activities</h3>
          <div className="flex items-center gap-5">
            <div className="relative">
              <IoSearchOutline className="w-5 h-5 absolute top-[0.5rem] left-1 text-gray-300 text-sm" />
              <input
                type="search"
                name="search"
                id="search"
                onChange={(e) => searchForUsersWithAccountName(e.target.value)}
                className="p-2 indent-5 text-xs bg-[#F9FBFF]"
                placeholder="Search by user name"
              />
            </div>
            <label htmlFor="filter" className="text-xs px-2 bg-[#F9FBFF]">
              Sort by:
              <select
                name="filter"
                id="filter"
                className={`py-2 px-1 font-semibold mx-4 text-xs ${
                  isActive === "newest" && "bg-[#F9FBFF]"
                }`}
                onClick={() => setIsActive("newest")}
                onChange={(e) => setFilter(e.target.value)}
              >
                <option value="newest">newest</option>
                <option value="oldest">oldest</option>
              </select>
            </label>
          </div>
        </section>
        <table className="w-full">
          <thead>
            <tr className="text-[#B5B7C0] text-sm border-b">
              <th className="p-3">SN</th>
              <th className="p-3">User name</th>
              <th className="p-3">Total No of Referrals</th>
              <th className="p-3">Total No. of Comp. referrals</th>
              <th className="p-3">Total Amount Earned</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan={5} className="text-center p-3">
                  <LoadingSpinner />
                </td>
              </tr>
            ) : data && data.length > 0 ? (
              data.map((item: any, index: number) => (
                <tr
                  key={index}
                  className="text-center hover:bg-gray-300 cursor-pointer"
                  onClick={() =>
                    navigate("referrer-details", {
                      state: {
                        referredCustomersDetails: item.referredCustomersDetails,
                        totalReferred,
                        totalComplete,
                        totalPending,
                      },
                    })
                  }
                >
                  <td className="p-3 text-sm border-b">
                    {String(index + 1).padStart(2, "0")}
                  </td>
                  <td className="p-3 text-sm border-b hover:underline">
                    {item.customerId}
                  </td>
                  <td className="p-3 text-sm border-b">
                    {item.totalReferredCustomers}
                  </td>
                  <td className="p-3 text-sm border-b">
                    {item.totalReferredWithOrders}
                  </td>
                  <td className="p-3 text-sm border-b text-secondary">
                    ₦{item.totalReferralEarned.toFixed(2)}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5} className="text-center p-3 text-secondary">
                  {searchedUser.length > 0
                    ? "User not found"
                    : "No customer has made any referrals"}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </section>
      <section className="p-3 my-5">
        <Pagination
          length={data.length}
          itemsPerPage={itemsPerPage}
          handlePagination={handlePagination}
          currentPage={currentPage}
          prevPage={prevPage}
          nextPage={nextPage}
        />
      </section>
    </main>
  );
};

export default ReferralIndex;
