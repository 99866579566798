import { useEffect, useState } from "react";
import { Preloader } from "../../components/elements/Preloader";
import axios from "axios";
import { useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";
import { IoSearchOutline } from "react-icons/io5";
import Pagination from "../../components/Pagination";
import { Link } from "react-router-dom";

const AllCanceledMandates = () => {
  const { token } = useAppSelector((store: RootState) => store.auth);
  const [allCanceledMandates, setAllCanceledMandates] = useState([]);
  const [searchedUser, setSearchedUser] = useState<any>([]);
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const searchForUsersWithAccountName = (value: string) => {
    setSearchedUser(value);
    if (value.length > 0) {
      const searchResult =
        allCanceledMandates?.filter((user: any) =>
          user.account_name?.toLowerCase().includes(value.toLowerCase())
        ) || null;
      setSearchResults(searchResult);
    } else {
      setSearchResults([]);
    }
  };

  const getAllCanceledMandates = async () => {
    setIsLoading((prev) => !prev);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/getCancelMandates`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setAllCanceledMandates(res.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllCanceledMandates();
  }, []);

  const [itemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastPost = currentPage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;
  const currentItems = allCanceledMandates.slice(
    indexOfFirstPost,
    indexOfLastPost
  );

  // Pagination
  const handlePagination = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const prevPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage !== Math.ceil(allCanceledMandates.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <main>
      <div className="bg-white p-3 mt-3 rounded-md">
        <div className="flex justify-between items-center mb-3">
          <h1 className="font-bold text-lg">Pending Mandate List</h1>
          <button
            type="button"
            className="text-sm bg-secondary p-2 text-white rounded-md"
          >
            <Link to={"/dashboard/all-canceled-mandates"}>
              All canceled mandates
            </Link>
          </button>
        </div>
        <IoSearchOutline className="w-6 h-6 absolute top-[0.6rem] left-2 text-gray-300 text-sm" />
        <input
          type="search"
          name="searchedUser"
          id="searchedUser"
          value={searchedUser}
          onChange={(e) => searchForUsersWithAccountName(e.target.value)}
          placeholder="Search user using account name"
          className="border p-2 rounded-md indent-7 w-full"
          disabled={allCanceledMandates.length === 0}
        />
      </div>
      <section>
        <table className="w-full my-3">
          <thead>
            <tr className="bg-gray-50 font-bold md:text-base text-sm h-12 text-left">
              <th className="pl-3">SN</th>
              <th className="pl-3">Client Account Name</th>
              <th className="pl-3">Date</th>
              <th className="pl-3">Time</th>
              <th className="pl-3"></th>
            </tr>
          </thead>
          <tbody className="md:text-base text-sm">
            {isLoading ? (
              <tr>
                <td colSpan={3} className="text-center p-5">
                  <Preloader />
                </td>
              </tr>
            ) : searchedUser.length > 0 ? (
              searchResults && searchResults.length > 0 ? (
                searchResults.map((item: any, index: number) => (
                  <tr key={index} className="border-b-2">
                    <td className="p-3">{index + 1}</td>
                    <td className="p-3">{item.account_name}</td>
                    <td className="p-3">
                      {new Date(item.date).toLocaleDateString()}
                    </td>
                    <td className="p-3">
                      {new Date(item.date).toLocaleTimeString()}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={10} className="text-center text-secondary p-3">
                    User with email not found
                  </td>
                </tr>
              )
            ) : !isLoading && allCanceledMandates.length > 0 ? (
              currentItems.map((item: any, index: number) => (
                <tr key={index} className="border-b-2">
                  <td className="p-3">{index + indexOfFirstPost + 1}</td>
                  <td className="p-3">{item.account_name}</td>
                  <td className="p-3">
                    {new Date(item.date).toLocaleDateString("en-us", {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    })}
                  </td>
                  <td className="p-3">
                    {new Date(item.date).toLocaleTimeString()}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={3} className="text-center text-secondary p-5">
                  No Mandates found!
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <section className="p-3 my-5">
          <Pagination
            length={
              searchResults.length > 0
                ? searchResults.length
                : allCanceledMandates.length
            }
            itemsPerPage={itemsPerPage}
            handlePagination={handlePagination}
            currentPage={currentPage}
            prevPage={prevPage}
            nextPage={nextPage}
          />
        </section>
      </section>
    </main>
  );
};

export default AllCanceledMandates;
