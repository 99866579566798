import axios from "axios";
import { useEffect, useState } from "react";
import LoadingSpinner from "../../components/elements/LoadingSpinner";
import { MultiSelect } from "react-multi-select-component";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getAllProducts } from "../../redux/thunk";
import { products } from "../../types/types";
import { Preloader } from "../../components/elements/Preloader";
import toast from "react-hot-toast";
import PackageModal from "../../components/modals/PackageModal";

const Index = () => {
  const auth: any = sessionStorage.getItem("authState");
  const jsonData = JSON.parse(auth);
  const token = jsonData.token;
  const [isLoading, setIsLoading] = useState({
    get: false,
    create: false,
    modal: false,
  });
  const [allPackages, setAllPackages] = useState([]);
  const [options, setOptions] = useState<Option[]>([]);
  const [selected, setSelected] = useState<any>([]);
  const [productIds, setProductIds] = useState<any>([]);
  const [errors, setErrors] = useState<any>({});
  const [viewPackage, setViewPackage] = useState(false);
  const [packageId, setPackageId] = useState("");
  const [selectedPackage, setSelectedPackage] = useState([]);
  const [image, setImage] = useState<any>(null);
  const [previewImage, setPreviewImage] = useState<any>({});
  const [inputValue, setInputValue] = useState({
    name: "",
    description: "",
    amount: "",
  });

  type Option = {
    label: string;
    value: string;
    quantity: number;
  };

  const dispatch = useAppDispatch();
  const { products } = useAppSelector((store) => store.products);

  useEffect(() => {
    dispatch(getAllProducts()).then(() => {
      setOptions(
        products.map((product: products) => ({
          label: `${product.name} (${product.measurement})`,
          value: product._id,
          quantity: 1
        }))
      );
    });
  }, [dispatch, products]);

  useEffect(() => {
    getAllChristmasPackages();
  }, []);

  const getAllChristmasPackages = async () => {
    setIsLoading((prev) => ({ ...prev, get: true }));

    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/packages`, {
        headers: {
          Authorization: token,
        },
      });
      setAllPackages(res.data);
    } catch (error: any) {
      console.error(error);
    } finally {
      setIsLoading((prev) => ({ ...prev, get: false }));
    }
  };

  useEffect(() => {
    setProductIds(selected);
  }, [selected]);

  const validateForm = () => {
    const newErrors: any = {};
    let hasErrors: boolean = false;

    if (!inputValue.name) {
      newErrors.name = "please add name";
      hasErrors = true;
    }
    if (!inputValue.amount) {
      newErrors.amount = "please add amount";
      hasErrors = true;
    }
    const onlyNumbersRegex = /^\d+$/;
    if (!onlyNumbersRegex.test(inputValue.amount)) {
      newErrors.amount = "Amount should contain only numbers";
      hasErrors = true;
    }
    if (!image || !image.hasOwnProperty("web")) {
      newErrors.webImage = "please upload a photo for the web app";
      hasErrors = true;
    }
    if (!image || !image.hasOwnProperty("mobile")) {
      newErrors.mobileImage = "please upload a photo for the mobile app";
      hasErrors = true;
    }
    if (!inputValue.description) {
      newErrors.description = "please add description";
      hasErrors = true;
    }
    if (productIds.length === 0) {
      newErrors.products = "please add products";
      hasErrors = true;
    }
    setErrors(newErrors);
    return hasErrors;
  };

  const handleFileChange = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.name === "webImage") {
        setImage((prev: any) => ({ ...prev, web: e.target.files[0] }));
        setPreviewImage((prev: any) => ({
          ...prev,
          web: URL.createObjectURL(e.target.files[0]),
        }));
      } else {
        setImage((prev: any) => ({ ...prev, mobile: e.target.files[0] }));
        setPreviewImage((prev: any) => ({
          ...prev,
          mobile: URL.createObjectURL(e.target.files[0]),
        }));
      }
    }
  };

  const createPackage = async (e: any) => {
    if (validateForm()) {
      return;
    }

    e.preventDefault();
    const payload = new FormData();
    payload.append("name", inputValue.name);
    payload.append("amount", inputValue.amount);
    payload.append("description", inputValue.description);
    payload.append("image", image.web);
    payload.append("image2", image.mobile);
    const formattedProduct = productIds.map((item: any) => ({
      productId: item.value,
      quantity: item.quantity,
    }));
    payload.append("productIds", JSON.stringify(formattedProduct));

    setIsLoading((prev) => ({ ...prev, create: true }));
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/create-package`,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      toast.success(res.data.message || "Created successfully!");
      handleClearForm();
      getAllChristmasPackages();
    } catch (error: any) {
      toast.error(error.message || "Failed to create package!");
    } finally {
      setIsLoading((prev) => ({ ...prev, create: false }));
    }
  };

  const handleClearForm = () => {
    setInputValue({ name: "", description: "", amount: "" });
    const fileInputs = document.querySelector('input[type="file"]') as any;
    fileInputs.value = "";
    setPreviewImage({});
    setImage(null);
    setSelected([]);
  };

  const handleRemoveSelectedProducts = (label: string) => {
    const updatedSelected = selected.filter(
      (item: any) => item.label !== label
    );
    setSelected(updatedSelected);
  };

  const handleClickPackage = async (id: string) => {
    setPackageId(id);
    setIsLoading((prev) => ({ ...prev, modal: true }));

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/packages/${id}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setSelectedPackage(res.data);
      setViewPackage(true);
    } catch (error: any) {
      console.error(error);
    } finally {
      setIsLoading((prev) => ({ ...prev, modal: false }));
    }
  };

  const handleQuantityChange = (productId: string, quantity: string) => {
    setSelected((prevProducts: any) => {
      return prevProducts.map((product: any) =>
        product.value === productId
          ? { ...product, quantity: parseInt(quantity, 10) }
          : product
      );
    });
  }; 
    
  return (
    <main>
      <h1 className="text-xl font-bold mb-2">Christmas Packages</h1>

      <section className="flex my-14 justify-evenly flex-wrap gap-3">
        {isLoading.get ? (
          <div className="w-fit mx-auto p-10">
            <LoadingSpinner />
          </div>
        ) : allPackages && allPackages.length > 0 ? (
          allPackages.map((packages: any, index: number) => (
            <div
              key={index}
              onClick={() => handleClickPackage(packages._id)}
              className={`bg-white p-5 rounded-md border flex flex-col justify-between cursor-pointer h-40 border-green-300 shadow-md w-60 ${
                isLoading.get && "animate-pulse "
              }`}
            >
              {packages._id === packageId && isLoading.modal ? (
                <Preloader />
              ) : (
                <>
                  <h2 className="text-xl font-semibold pb-4 text-gray-700 caption-bottom">
                    {packages?.name}
                  </h2>
                  <p className="text-center font-semibold">
                    ₦{packages?.amount}
                  </p>
                  <p className="font-semibold text-3xl text-right">
                    {packages.productIds.length}
                    <span className="text-xs">
                      item{packages?.productIds?.length > 1 && "s"}
                    </span>
                  </p>
                </>
              )}
            </div>
          ))
        ) : (
          <p className="text-xl text-center p-10 text-secondary">
            No packages found
          </p>
        )}
      </section>

      <section className="bg-white px-10 py-5">
        <h3 className="font-semibold text-xl">Create a new Package</h3>
        <section className="flex justify-between items-start md:flex-row flex-col gap-3 my-3">
          <form className="md:w-1/2 border p-3 shadow-md text-sm">
            <label className="font-medium" htmlFor="name">
              Name of package:
            </label>
            <input
              type="text"
              name="name"
              id="name"
              value={inputValue.name}
              onChange={(e) => {
                const { name, value } = e.target;
                setInputValue((prev) => ({ ...prev, [name]: value }));
              }}
              className="border p-2 block w-full my-2 rounded"
            />
            {errors.name && (
              <span className="block -mt-2 text-red-500 text-sm">
                {errors.name}
              </span>
            )}
            <label className="font-medium" htmlFor="amount">
              Amount:
            </label>
            <input
              type="text"
              name="amount"
              id="amount"
              value={inputValue.amount}
              onChange={(e) => {
                const { name, value } = e.target;
                setInputValue((prev) => ({ ...prev, [name]: value }));
              }}
              className="border p-2 block w-full my-2 rounded"
            />
            {errors.amount && (
              <span className="block -mt-2 text-red-500 text-sm">
                {errors.amount}
              </span>
            )}
            <label className="font-medium" htmlFor="webImage">
              Upload Web Image:
            </label>
            <input
              type="file"
              name="webImage"
              id="webImage"
              accept="image/*"
              onChange={(e) => handleFileChange(e)}
              className="border p-2 block w-full my-2 rounded"
            />
            {errors.webImage && (
              <span className="block -mt-2 text-red-500 text-sm">
                {errors.webImage}
              </span>
            )}
            <label className="font-medium" htmlFor="mobileImage">
              Upload Mobile Image:
            </label>
            <input
              type="file"
              name="mobileImage"
              id="mobileImage"
              accept="image/*"
              onChange={(e) => handleFileChange(e)}
              className="border p-2 block w-full my-2 rounded"
            />
            {errors.mobileImage && (
              <span className="block -mt-2 text-red-500 text-sm">
                {errors.mobileImage}
              </span>
            )}
            <label className="font-medium" htmlFor="description">
              Package Description:
            </label>
            <textarea
              name="description"
              id="description"
              value={inputValue.description}
              onChange={(e) => {
                const { name, value } = e.target;
                setInputValue((prev) => ({ ...prev, [name]: value }));
              }}
              cols={30}
              className="border p-2 mb-3 block w-full my-2 rounded"
            ></textarea>
            {errors.description && (
              <span className="block -mt-2 text-red-500 text-sm">
                {errors.description}
              </span>
            )}
            <label className="font-medium" htmlFor="products">
              Select Products:
            </label>
            <MultiSelect
              options={options}
              value={selected}
              onChange={setSelected}
              labelledBy="Select"
            />
            <p className="text-xs italic">
              {selected.length > 0
                ? selected.map((item: any) => (
                    <li key={item.label}  className="cursor-pointer flex gap-5 items-center hover:text-secondary my-1">
                      <span
                        key={item.value}
                        title="remove"
                      >
                        {item.label}
                        <br />
                      </span>
                      <input
                        type="number"
                        min="1"
                        value={item.quantity}
                        className="text-sm bg-gray-50 rounded-md w-20 p-1 border outline-none border-green-100 "
                        placeholder="enter qty"
                        onChange={(e) =>
                          handleQuantityChange(item.value, e.target.value)
                        }
                      />
                    </li>
                  ))
                : "No products selected"}
            </p>
            {errors.products && (
              <span className="block -mt-2 text-red-500 text-sm">
                {errors.products}
              </span>
            )}
            <button
              type="button"
              onClick={(e) => createPackage(e)}
              className="p-2 bg-secondary text-sm rounded-md my-3 text-white"
            >
              {isLoading.create ? <Preloader /> : "Create Package"}
            </button>
          </form>
          <section className="w-1/2">
            <section
              className={`border h-56 px-5 pt-5 pb-10 mb-2 ${
                previewImage.web ? "block" : "hidden"
              }`}
            >
              <span className="text-sm">Web Preview: </span>
              {previewImage.web && (
                <img
                  src={previewImage.web}
                  alt="Preview web"
                  className="object-contain h-full w-full"
                  style={{ maxWidth: "100%" }}
                />
              )}
            </section>
            <section
              className={`border h-56 px-5 pt-5 pb-10 ${
                previewImage.mobile ? "block" : "hidden"
              }`}
            >
              <span className="text-sm">Mobile Preview: </span>
              {previewImage.mobile && (
                <img
                  src={previewImage.mobile}
                  alt="Preview"
                  className="object-contain h-full w-full"
                  style={{ maxWidth: "100%" }}
                />
              )}
            </section>
          </section>
        </section>
      </section>

      {viewPackage && (
        <PackageModal
          setViewPackage={setViewPackage}
          id={packageId}
          selectedPackage={selectedPackage}
          refreshPage={getAllChristmasPackages}
        />
      )}
    </main>
  );
};

export default Index;
