import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  createProductItem,
  createProductType,
  deleteProduct,
  deleteProductType,
  getAllProductType,
  getAllProducts,
  getFees,
  getProduct,
  updateSingleProduct,
} from "../redux/thunk";
import toast from "react-hot-toast";
import { FaUpload } from "react-icons/fa";
import LoadingSpinner from "../components/elements/LoadingSpinner";
import ServiceModal from "../components/modals/ServiceModal";
import { Preloader } from "../components/elements/Preloader";

export const Store = () => {
  const adminLevel = sessionStorage.getItem("adminLevel");
  const [activeTab, setActiveTab] = useState("product");
  const [showAddProduct, setShowAddProduct] = useState(false);
  const [showUpdateProduct, setShowUpdateProduct] = useState(false);
  const [showAddCategory, setShowAddCategory] = useState(false);
  const [showUpdateCategory, setShowUpdateCategory] = useState(false);
  const [showServiceModal, setShowServiceModal] = useState(false);
  const [searchProducts, setSearchProducts] = useState("");
  const [image, setImage] = useState<any>(null);
  const [preview, setPreview] = useState("");
  const [searchResult, setSearchResult] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState({
    updateProd: false,
    deleteProd: false,
    createProd: false,
    createCat: false,
    deleteCat: false,
  })
  const [singleProduct, setSingleProduct] = useState<any>({
    name: "",
    type: "",
    measurement: "",
    quantity: 0,
    price: 0,
    description: "",
    image: "",
  });
  const [categoryDetails, setCategoryDetails] = useState<any>({
    id: "",
    name: "",
  });
  const [addCategory, setAddCategory] = useState<any>({
    name: "",
    img: "",
  });
  const [addProduct, setAddProduct] = useState<any>({
    name: "",
    measurement: "",
    image: "",
    type: "",
    price: "",
    quantity: "",
    description: "",
  });

  const dispatch = useAppDispatch();
  const { products, categories, status } = useAppSelector(
    (store) => store.products
  );
  const {fees} = useAppSelector((store) => store.fees)

  useEffect(() => {
    dispatch(getAllProducts());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getFees())    
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllProductType());
  }, [dispatch]);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setSingleProduct({
        ...singleProduct,
        image: file,
      });
      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result === "string") {
          setPreview(reader.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleIconClick = () => {
    const fileInput = document.getElementById(
      "fileInput"
    ) as HTMLInputElement | null;
    fileInput?.click();
  };

  const getProductDetails = async (id: any) => {
    try {
      const productResponse = await dispatch(getProduct(id));
      const productData = productResponse.payload;
      setSingleProduct(productData);
      setShowUpdateProduct((prev) => !prev);
    } catch (error: any) {
      console.error("Error fetching product details:", error);
      toast.error(error.message || "An error occured!");
    }
  };

  const handleCategoryClick = (catId: string, name: string) => {
    setShowUpdateCategory((prev) => !prev);
    setCategoryDetails({
      id: catId,
      name,
    });
  };

  const handleProductClick = (id: any) => {
    getProductDetails(id);
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setSingleProduct({
      ...singleProduct,
      [name]: value,
    });
  };

  const handleUpdateProduct = async (productId: any) => {
    setIsLoading((prev) => ({...prev, updateProd: true}))
    try {
      const formData = new FormData();
      formData.append("name", singleProduct.name);
      formData.append("quantity", singleProduct.quantity);
      formData.append("description", singleProduct.description);
      formData.append("measurement", singleProduct.measurement);
      formData.append("price", singleProduct.price);
      formData.append("image", singleProduct.image);

      const response = await dispatch(
        updateSingleProduct({ data: formData, id: productId })
      );

      if (response.payload) {
        toast.success(response.payload.message);
        dispatch(getAllProducts());
        setShowUpdateProduct((prev) => !prev);
      } else {
        toast.error("Failed to update product");
      }
    } catch (error: any) {
      toast.error(error.message || "An error occurred!");
    } finally {
      setIsLoading((prev) => ({...prev, updateProd: false}))
    }
  };

  const handleDeleteProduct = (productId: any) => {
    setIsLoading((prev) => ({...prev, deleteProd: true}))
    try {
      dispatch(deleteProduct(productId)).then((response) => {
        dispatch(getAllProducts());

        toast.success(
          response.payload.message || "Product deleted successfully!"
        );
        setShowUpdateProduct((prev) => !prev);
      });
    } catch (error: any) {
      toast.error(error.message || "Deletion failed");
    } finally {
      setIsLoading((prev) => ({...prev, deleteProd: false}))
    }
  };

  const handleDeleteCategory = (categoryId: string) => {
    setIsLoading((prev) => ({...prev, deleteCat: true}))
    try {
      dispatch(deleteProductType(categoryId)).then((response) => {
        dispatch(getAllProductType());
        toast.success(response.payload.message);
        setShowUpdateCategory((prev) => !prev);
      });
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setIsLoading((prev) => ({...prev, deleteCat: false}))
    }
  };

  const catFileChange = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      setAddCategory({
        ...addCategory,
        img: e.target.files[0],
      });
    }
  };

  const productFileChange = (e: any, name: any) => {
    if (name === "image" && e.target.files && e.target.files.length > 0) {
      setAddProduct({
        ...addProduct,
        image: e.target.files[0],
      });
    } else {
      setAddProduct({
        ...addProduct,
        [name]: e.target.value,
      });
    }
  };

  const createCategory = async () => {
    const formData = new FormData();
    formData.append("type", addCategory.name);
    formData.append("image", addCategory.img);
    setIsLoading((prev) => ({...prev, createCat: true}))

    try {
      dispatch(createProductType(formData)).then(() => {
        dispatch(getAllProductType()).then(() => {
          toast.success("New Category Created!");
          setShowAddCategory((prev) => !prev);
        });
      });
    } catch (error: any) {
      toast.error(error.message || "Category not created!");
    } finally {
      setIsLoading((prev) => ({...prev, createCat: false}))
    }
  };

  const handleSearch = (value: string) => {
    setSearchProducts(value);
    if (searchProducts.length > 0) {
      const searchedItems = products.filter((item: any) =>
        item.name.toLowerCase().includes(value.toLowerCase())
      );
      setSearchResult(searchedItems);
    }
  };

  const createProduct = async () => {
    const formData = new FormData();
    formData.append("name", addProduct.name);
    formData.append("image", addProduct.image);
    formData.append("measurement", addProduct.measurement);
    formData.append("type", addProduct.type);
    formData.append("price", addProduct.price);
    formData.append("quantity", addProduct.quantity);
    formData.append("description", addProduct.description);

    setIsLoading((prev) => ({...prev, createProd: true}))
    try {
      dispatch(createProductItem(formData)).then((response) => {
        dispatch(getAllProducts()).then(() => {
          toast.success("New Product Created!");
          setShowAddProduct((prev) => !prev);
        });
      });
    } catch (error: any) {
      toast.error(error.message || "Product not created!");
    } finally {
      setIsLoading((prev) => ({...prev, createProd: false}))
    }
  };

  return (
    <>
      <section className="w-full bg-[#F6F4F8] px-4 lg:px-6 2xl:px-8 pt-2">
        <div className="bg-white w-full rounded-sm p-5">
          <h2 className="font-bold text-xl">Actions</h2>
          <div className="flex gap-3 my-5">
            <button
              className="border rounded-md px-4 py-2 bg-secondary text-white"
              onClick={() => setShowAddProduct(true)}
            >
              Add Product
            </button>
            <button
              className="border rounded-md px-4 py-2 bg-secondary text-white"
              onClick={() => setShowAddCategory(true)}
            >
              Add Category
            </button>
            {adminLevel === "superadmin" && (
              <button
                type="button"
                className="border rounded-md px-4 py-2 bg-secondary text-white"
                onClick={() => setShowServiceModal((prev) => !prev)}
              >
                Set Service and Delivery Fee
              </button>
            )}
          </div>
        </div>
        <section className="bg-white w-full rounded-sm my-5 p-5">
          <div className="flex items-center gap-4">
            <span
              className={`cursor-pointer mr-5 ${
                activeTab === "product" ? "border-b-4 border-[#008b50]" : "null"
              }`}
              onClick={() => setActiveTab("product")}
            >
              Products
            </span>
            <span
              className={`cursor-pointer ${
                activeTab === "category"
                  ? "border-b-4 border-[#008b50]"
                  : "null"
              }`}
              onClick={() => setActiveTab("category")}
            >
              Categories
            </span>
            {adminLevel === "superadmin" && (
              <span
                className={`cursor-pointer ml-5 ${
                  activeTab === "service"
                    ? "border-b-4 border-[#008b50]"
                    : "null"
                }`}
                onClick={() => setActiveTab("service")}
              >
                Service & Delivery Fee
              </span>
            )}

            <input
              type="search"
              name="search"
              id="search"
              value={searchProducts}
              onChange={(e) => handleSearch(e.target.value)}
              placeholder="Search products"
              className={`${
                activeTab === "product"
                  ? "text-sm border block border-secondary rounded-md p-2"
                  : "hidden"
              }`}
            />
          </div>
          <section
            className={`${
              (activeTab === "product" || activeTab === "category") &&
              "w-full flex flex-wrap justify-evenly rounded my-5"
            }`}
          >
            {status === "loading" ? (
              <LoadingSpinner />
            ) : activeTab === "product" ? (
              searchResult.length > 0 ? (
                searchResult.map((product, index) => (
                  <div
                    className="border m-3 p-3 rounded-lg w-56 flex flex-col justify-between cursor-pointer"
                    onClick={() => handleProductClick(product._id)}
                    key={index}
                  >
                    <p className="text-sm my-2">
                      {product.name} {product.measurement}
                    </p>
                    <div className="flex items-end justify-evenly">
                      <div className="w-20 h-32 rounded-md">
                        <img
                          src={product.image}
                          className="w-full h-full rounded-md"
                          alt={product.name}
                        />
                      </div>
                      <p className="font-semibold">{product.price}</p>
                    </div>
                  </div>
                ))
              ) : (
                products.map((product, index) => (
                  <div
                    className="border m-3 p-3 rounded-lg w-56 flex flex-col justify-between cursor-pointer"
                    onClick={() => handleProductClick(product._id)}
                    key={index}
                  >
                    <p className="text-sm my-2">
                      {product.name} {product.measurement}
                    </p>
                    <div className="flex items-end justify-evenly">
                      <div className="w-20 h-32 rounded-md">
                        <img
                          src={product.image}
                          className="w-full h-full rounded-md"
                          alt={product.name}
                        />
                      </div>
                      <p className="font-semibold">{product.price}</p>
                    </div>
                  </div>
                ))
              )
            ) : activeTab === "category" ? (
              categories.map((category: any, index: any) => (
                <div
                  key={index}
                  className="border m-3 p-3 rounded-lg w-56 cursor-pointer"
                  onClick={() =>
                    handleCategoryClick(category._id, category.type)
                  }
                >
                  <p className="text-sm my-2 font-semibold">
                    {category.type.toUpperCase()}
                  </p>
                  <div className="w-full h-32 rounded-md">
                    <img
                      src={category.image}
                      className="w-full h-full rounded-md"
                      alt={category.type}
                    />
                  </div>
                </div>
              ))
            ) : (
              <div className="my-2 text-2xl font-medium">
                <div className="flex gap-2 my-2">
                  <p className="">Service Fee: </p>
                  <p>{fees.allfees ? fees.allfees[fees.allfees.length - 1].serviceFee : "0"}</p>
                </div>
                <div className="flex gap-2">
                  <p className="">Delivery Fee: </p>
                  <p>{fees.allfees ? fees.allfees[fees.allfees.length - 1].deliveryFee : "0"}</p>
                </div>
                <div className="flex gap-2">
                  <p className="">Interest: </p>
                  <p>{fees.allfees ? fees.allfees[fees.allfees.length - 1].interest : "0"}%</p>
                </div>
              </div>
            )}
          </section>
        </section>
        {showServiceModal && (
          <ServiceModal setShowServiceModal={setShowServiceModal} />
        )}

        {showAddProduct && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
            <div className="bg-white p-4 rounded-md" style={{ width: "500px" }}>
              <div className="text-dark p-2 rounded-t-md mb-4">
                <div className="relative w-full">
                  <h2 className="font-bold text-xl">Add Product</h2>
                  <button
                    onClick={() => setShowAddProduct(false)}
                    className="text-dark absolute right-0 top-0"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>

                <form action="">
                  <div className="flex justify-between my-2">
                    <label htmlFor="name">Product Name:</label>
                    <input
                      className="border p-2 "
                      type="text"
                      name="name"
                      id="name"
                      onChange={(e) => productFileChange(e, "name")}
                    />
                  </div>
                  <div className="border flex w-full justify-center p-2">
                    <select
                      name="type"
                      id="type"
                      onChange={(e) => productFileChange(e, "type")}
                    >
                      <option value="">Select type</option>
                      {categories?.map((type: any, index: number) => (
                        <option key={index} value={type.type}>
                          {type.type}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="flex justify-between my-2">
                    <label htmlFor="measurement">Measurement:</label>
                    <input
                      className="border p-2 "
                      type="text"
                      name="measurement"
                      id="measurement"
                      onChange={(e) => productFileChange(e, "measurement")}
                    />
                  </div>
                  <div className="flex justify-between my-2">
                    <label htmlFor="price">Price:</label>
                    <input
                      className="border p-2 "
                      type="number"
                      name="price"
                      id="price"
                      onChange={(e) => productFileChange(e, "price")}
                    />
                  </div>
                  <div className="flex justify-between my-2">
                    <label htmlFor="quantity">Quantity:</label>
                    <input
                      className="border p-2 "
                      type="number"
                      name="quantity"
                      id="quantity"
                      onChange={(e) => productFileChange(e, "quantity")}
                    />
                  </div>
                  <div className="flex justify-between my-2">
                    <label htmlFor="image">Add Image:</label>
                    <input
                      type="file"
                      name="image"
                      id="image"
                      onChange={(e) => productFileChange(e, "image")}
                    />
                  </div>
                  <div className="flex justify-between my-2">
                    <label htmlFor="desc">Description:</label>
                    <input
                      className="border p-2 "
                      type="text"
                      name="desc"
                      id="desc"
                      onChange={(e) => productFileChange(e, "description")}
                    />
                  </div>
                </form>
                <div>
                  <button
                    type="button"
                    onClick={createProduct}
                    className="px-5 py-2 mr-3 rounded text-white bg-secondary"
                  >
                    {isLoading.createProd ? <Preloader /> : "Create"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {showUpdateProduct && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
            <div className="bg-white p-4 rounded-md" style={{ width: "500px" }}>
              <div className="  text-dark p-2 rounded-t-md mb-4">
                <div className="relative w-full">
                  <h2 className="font-bold text-xl">Update Product</h2>
                  <button
                    onClick={() => setShowUpdateProduct((prev) => !prev)}
                    className="text-dark absolute right-0 top-0"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>

                <form action="">
                  <div className="bg-gray-100 h-24 w-24 shadow relative flex items-center justify-center">
                    <img
                      src={preview || singleProduct.image}
                      alt={singleProduct.name}
                      className="h-24 w-24 object-cover"
                    />
                    <input
                      type="file"
                      id="fileInput"
                      style={{ display: "none" }}
                      onChange={handleImageChange}
                      accept="image/*"
                    />
                    <div
                      className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 cursor-pointer"
                      onClick={handleIconClick}
                    >
                      <FaUpload className="text-white text-xl" />
                    </div>
                  </div>
                  <div className="flex justify-between my-2">
                    <label htmlFor="name">Product Name:</label>
                    <input
                      className="border p-2"
                      type="text"
                      name="name"
                      id="name"
                      value={singleProduct.name}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </div>
                  <div className="border bg-gray-300 flex w-full justify-center p-2">
                    <select name="type" id="type" className="bg-gray-300">
                      <option value={singleProduct.type}>
                        {singleProduct.type}
                      </option>
                    </select>
                  </div>
                  <div className="flex justify-between my-2">
                    <label htmlFor="measurement">Measurement:</label>
                    <input
                      className="border p-2 "
                      type="text"
                      name="measurement"
                      id="measurement"
                      value={singleProduct.measurement}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </div>
                  <div className="flex justify-between my-2">
                    <label htmlFor="price">Price:</label>
                    <input
                      className="border p-2 "
                      type="number"
                      name="price"
                      id="price"
                      value={singleProduct.price}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </div>
                  <div className="flex justify-between my-2">
                    <label htmlFor="quantity">Quantity:</label>
                    <input
                      className="border p-2"
                      type="number"
                      name="quantity"
                      id="quantity"
                      value={singleProduct.quantity}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </div>
                  <div className="flex justify-between my-2">
                    <label htmlFor="description">Description:</label>
                    <input
                      className="border p-2"
                      type="text"
                      name="description"
                      id="description"
                      value={singleProduct.description}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </div>
                </form>
                <div>
                  <button
                    type="button"
                    onClick={() => handleUpdateProduct(singleProduct._id)}
                    className="px-5 py-2 mr-3 rounded text-white bg-secondary"
                  >
                    {isLoading.updateProd ? <Preloader /> : "Update"}
                  </button>
                  <button
                    type="button"
                    onClick={() => handleDeleteProduct(singleProduct._id)}
                    className="px-5 py-2 mr-3 rounded text-white bg-red-500"
                  >
                    {isLoading.deleteProd ? <Preloader /> : "Delete"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {showAddCategory && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
            <div className="bg-white p-4 rounded-md" style={{ width: "500px" }}>
              <div className="text-dark p-2 rounded-t-md mb-4">
                <div className="relative w-full">
                  <h2 className="font-bold text-xl">Add Category</h2>
                  <button
                    onClick={() => setShowAddCategory(false)}
                    className="text-dark absolute right-0 top-0"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>

                <div>
                  <div className="flex justify-between my-2">
                    <label htmlFor="name">Category Name:</label>
                    <input
                      className="border p-2 "
                      type="text"
                      name="name"
                      id="name"
                      onChange={(e) =>
                        setAddCategory({ ...addCategory, name: e.target.value })
                      }
                    />
                  </div>
                  <div className="flex justify-between my-2">
                    <label htmlFor="img">Add Image:</label>
                    <input
                      type="file"
                      name="img"
                      id="img"
                      onChange={(e) => catFileChange(e)}
                    />
                  </div>
                </div>
                <div>
                  <button
                    type="button"
                    onClick={createCategory}
                    className="px-5 py-2 mr-3 rounded text-white bg-secondary"
                  >
                    {isLoading.createCat ? <Preloader /> : "Submit"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {showUpdateCategory && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
            <div className="bg-white p-4 rounded-md" style={{ width: "500px" }}>
              <div className="text-dark p-2 rounded-t-md mb-4">
                <div className="relative w-full">
                  <h2 className="font-bold text-xl">Update Category</h2>
                  <button
                    onClick={() => setShowUpdateCategory(false)}
                    className="text-dark absolute right-0 top-0"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>

                <div className="flex justify-between my-2">
                  <label htmlFor="name">Category Name:</label>
                  <input
                    className="border p-2"
                    type="text"
                    name="name"
                    id="name"
                    value={categoryDetails.name}
                    disabled
                  />
                </div>
                <div>
                  <button
                    type="button"
                    onClick={() => setShowUpdateCategory(false)}
                    className="px-5 py-2 mr-3 rounded text-white bg-secondary"
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    onClick={() => handleDeleteCategory(categoryDetails.id)}
                    className="px-5 py-2 mr-3 rounded text-white bg-red-500"
                  >
                    {isLoading.deleteCat ? <Preloader /> : "Delete"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};
