import { useEffect, useState } from "react";
import { getDashboardStats } from "../../redux/thunk";
import {
  Chart as ChartJs,
  Tooltip,
  Legend,
  BarElement,
  ArcElement,
  LinearScale,
  CategoryScale,
} from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJs.register(
  Tooltip,
  Legend,
  ArcElement,
  BarElement,
  LinearScale,
  CategoryScale
);

const PieChart = () => {
  const [data, setData] = useState([]);
  
  useEffect(() => {
    const getStats = async () => {
      await getDashboardStats().then((res) => {
        setData(res);
      });
    };
    getStats();
  }, []);

  const pieChartData = {
    labels: ["Employed", "Self-employed", "Student"],
    datasets: [
      {
        label: "Verified users",
        data: [
          data.employedCustomersCount,
          data.selfEmployedCustomersCount,
          data.selfEmployedCustomersCount,
        ],
        backgroundColor: ["#92E3A9", "#F1D63A", "#7086FD"],
        hoverOffset: 2,
      },
    ],
  };

  const pieChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          boxWidth: 20,
          boxHeight: 20,
        },
      },
    },
  };

  return (
    <>
      <Pie options={pieChartOptions} data={pieChartData} />
    </>
  );
};

export default PieChart;
