import { MdOutlineCancel } from "react-icons/md";
import Modal, { ModalContent } from "../elements/Modal";
import { formatNumber } from "../../redux/thunk";

const DetailedOrderModal = ({ historyDetails, closeModal }: any) => {
  return (
    <Modal
      open={true}
      onClose={closeModal}
      className="flex items-center justify-center"
    >
      <ModalContent className="mx-3 p-6 rounded-md shadow-lg flex flex-col justify-center items-center bg-white w-[80%] md:w-[55%] lg:w-[50%] xl:w-[30%] ">
        <section className="w-full h-96 overflow-y-scroll p-2">
          <div className="flex justify-end">
            <MdOutlineCancel
              className="text-2xl cursor-pointer"
              onClick={closeModal}
            />
          </div>

          {historyDetails
            .slice()
            .reverse()
            .map((history: any, index: number) => (
              <section key={index}>
                <h3 className="font-semibold">
                  Date:{" "}
                  {new Date(history.orderDate).toLocaleDateString("en-us", {
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                  })}
                </h3>
                <p className="text-xs text-secondary">
                  Order No: {history.orderNumber}
                </p>
                <section>
                  <h3 className="font-semibold my-2">Order Details</h3>
                  {history.orderItems.map((order: any, index: number) => (
                    <section
                      key={index}
                      className="w-full border border-secondary my-1 rounded-md"
                    >
                      <div className="flex p-1 justify-between">
                        <p>Item name</p>
                        <p>{order.name}</p>
                      </div>
                      <div className="flex p-1 justify-between">
                        <p>Item quantity</p>
                        <p>{order.quantity}</p>
                      </div>
                      <div className="flex p-1 justify-between">
                        <p>Total price</p>
                        <p>{formatNumber(order.totalPrice)}</p>
                      </div>
                    </section>
                  ))}
                </section>
                <p className="font-semibold">
                  Total Price: {formatNumber(history.allItemsTotalPrice)}
                </p>
              </section>
            ))}
        </section>
      </ModalContent>
    </Modal>
  );
};

export default DetailedOrderModal;
