import { useEffect, useState } from "react";
import VAcctModal from "../components/modals/VAcctModal";
import axios from "axios";
import { IoSearchOutline } from "react-icons/io5";
import Pagination from "../components/Pagination";

export const VirtualAccount = () => {
  const [allAccounts, setAllAccounts] = useState<[]>([]);
  const [vAcct, setVAcct] = useState({});
  const [showVAcct, setShowVAcct] = useState(false);
  const [searchedUser, setSearchedUser] = useState<any>([]);
  const [searchResults, setSearchResults] = useState([]);

  const [itemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastPost = currentPage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;
  const currentItems = allAccounts.slice(indexOfFirstPost, indexOfLastPost);

  // Pagination
  const handlePagination = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const prevPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage !== Math.ceil(allAccounts.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    const fetchAcct = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/getAllVirtualAccountDetails`
        );
        setAllAccounts(res.data.customers);
      } catch (error) {
        console.error(error);
      }
    };
    fetchAcct();
  }, []);

  const handleViewAcct = (acctData: object) => {
    setVAcct(acctData);
    setShowVAcct((prev) => !prev);
  };

  const searchForUsersWithEmail = (value: string) => {
    setSearchedUser(value);
    if (value.length > 0) {
      const searchResult =
        allAccounts?.filter((user: any) =>
          user.email?.toLowerCase().includes(value.toLowerCase())
        ) || null; // Fallback to empty array if customers is undefined
      setSearchResults(searchResult);
    } else {
      setSearchResults([]); // Clear results when input is empty
    }
  };

  return (
    <main className="w-full">
      <div className="bg-white p-3 rounded-md mb-3 flex items-center justify-between">
        <h1 className="font-bold text-lg">Virtual Account</h1>
        <div className="relative md:w-[30rem] w-fit">
          <IoSearchOutline className="w-6 h-6 absolute top-[0.6rem] left-2 text-gray-300" />
          <input
            type="search"
            name="searchedUser"
            id="searchedUser"
            value={searchedUser}
            onChange={(e) => searchForUsersWithEmail(e.target.value)}
            placeholder="Search user using email"
            className="border p-2 rounded-md indent-7 w-full"
            disabled={allAccounts.length === 0}
          />
        </div>
      </div>
      <section>
        <table className="w-full text-left">
          <thead className="bg-gray-50 h-12 rounded-md">
            <tr>
              <th className="px-2">SN</th>
              <th className="px-2">Name</th>
              <th className="px-2">Email</th>
              <th className="px-2">Phone Number</th>
              <th className="px-2">Account</th>
            </tr>
          </thead>
          <tbody>
            {searchedUser.length > 0 ? (
              searchResults && searchResults.length > 0 ? (
                searchResults.map((item: any, index) => (
                  <tr key={index} className="border-b">
                    <td className="p-2">{index + 1}</td>
                    <td className="p-2">
                      {item.lastName + " " + item.firstName}
                    </td>
                    <td className="p-2">{item.email}</td>
                    <td className="p-2">{item.phoneNumber}</td>
                    <td className="p-2">
                      <button
                        type="button"
                        onClick={() => handleViewAcct(item.virtualAccount)}
                        className="bg-secondary text-sm text-white p-2 rounded-md hover:bg-green-700"
                      >
                        View Account
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={10} className="text-center text-secondary p-3">
                    User with email not found
                  </td>
                </tr>
              )
            ) : (
              currentItems.map((item: any, index) => (
                <tr key={index} className="border-b">
                  <td className="p-2">{index + indexOfFirstPost + 1}</td>
                  <td className="p-2">
                    {item.lastName + " " + item.firstName}
                  </td>
                  <td className="p-2">{item.email}</td>
                  <td className="p-2">{item.phoneNumber}</td>
                  <td className="p-2">
                    <button
                      type="button"
                      onClick={() => handleViewAcct(item.virtualAccount)}
                      className="bg-secondary text-sm text-white p-2 rounded-md hover:bg-green-700"
                    >
                      View Account
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </section>
      <section className="p-3 my-5">
        <Pagination
          length={
            searchResults.length > 0 ? searchResults.length : allAccounts.length
          }
          itemsPerPage={itemsPerPage}
          handlePagination={handlePagination}
          currentPage={currentPage}
          prevPage={prevPage}
          nextPage={nextPage}
        />
      </section>

      {showVAcct && <VAcctModal setShowVAcct={setShowVAcct} acctData={vAcct} />}
    </main>
  );
};
