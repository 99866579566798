import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { formatNumber, getDefaulters, getSingleCustomer } from "../redux/thunk";
import BVNVerificationModal from "../components/modals/BVNVerificationModal";
import { EMandateModal } from "../components/modals/EMandateModal";
import axios from "axios";
import { CardDebit } from "../components/modals/CardDebit";
import { RootState } from "../redux/store";
import { IoSearchOutline } from "react-icons/io5";
import { Preloader } from "../components/elements/Preloader";
import Pagination from "../components/Pagination";

export const Defaulters = () => {
  const adminLevel = sessionStorage.getItem("adminLevel");
  const [bvnModalOpen, setBvnModalOpen] = useState(false);
  const [filter, setFilter] = useState("all");
  const [selectedBvn, setSelectedBvn] = useState<any>([]);
  const [defaultersList, setDefaultersList] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [emandateModal, setEmandateModal] = useState(false);
  const [defaultAmount, setDefaultAmount] = useState("");
  const [allEmandate, setAllEmandate] = useState<any>([]);
  const [cardDebitModal, setCardDebitModal] = useState(false);
  const [allCards, setAllCards] = useState<any>([]);
  const [searchedUser, setSearchedUser] = useState<any>([]);
  const [searchResults, setSearchResults] = useState<any>([]);
  const [isLoadingEmandate, setIsLoadingEmandate] = useState<{
    [key: number]: Boolean;
  }>({});
  const [acctClick, setAcctClick] = useState({
    monoId: "",
    overdueAmount: "",
    accounts: [],
  });
  const { token } = useAppSelector((store: RootState) => store.auth);

  const fetchMandates = async () => {
    try {
      const res: any = await axios.get(
        `${process.env.REACT_APP_API_URL}/getActiveMandates
        `,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setAllEmandate(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const dispatch = useAppDispatch();

  const handleBvnClick = (id: any) => {
    dispatch(getSingleCustomer(id)).then((res: any) => {
      setSelectedBvn(res.payload.customer);
      setBvnModalOpen(true);
    });
  };

  const fetchCards = async (id: string) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/getCardDetailsByUserId/${id}
        `,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setAllCards(res.data.cardDetails);
    } catch (error: any) {
      console.error(error.message);
    }
  };

  const handleCardDebit = async (userId: string, defaultAmount: any) => {
    await fetchCards(userId);
    setCardDebitModal((prev) => !prev);
    setDefaultAmount(defaultAmount);
  };

  useEffect(() => {
    setIsLoading((prev) => !prev);
    dispatch(getDefaulters()).then((response: any) => {
      setDefaultersList(response.payload);
      setIsLoading((prev) => !prev);
    });
  }, [dispatch]);

  const handleEmandate = async (
    monoId: string,
    amount: string,
    index: number
  ) => {
    setIsLoadingEmandate((prev) => ({ ...prev, [index]: true }));
    await fetchMandates().then(() =>
      setIsLoadingEmandate((prev) => ({ ...prev, [index]: false }))
    );
    setDefaultAmount(amount);
    setAcctClick((prev) => ({
      ...prev,
      monoId: monoId,
    }));
  };

  useEffect(() => {
    if (acctClick.monoId) {
      const acctArray = allEmandate.filter(
        (acct: any) => acct.customer === acctClick.monoId
      );
      const defaulterArray = defaultersList.filter(
        (acct: any) => acct.monoId === acctClick.monoId
      );

      setAcctClick((prev) => ({
        ...prev,
        accounts: acctArray,
        overdueAmount: defaulterArray[0].OverdueAmount,
      }));

      setEmandateModal((prev) => !prev);
    }
  }, [allEmandate, acctClick.monoId, defaultersList]);

  const searchForUsersWithEmail = (value: string) => {
    setSearchedUser(value);
    if (value.length > 0) {
      const searchResult =
        defaultersList?.filter((user: any) =>
          user.email?.toLowerCase().includes(value.toLowerCase())
        ) || null;
      setSearchResults(searchResult);
    } else {
      setSearchResults([]);
    }
  };

  const [itemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastPost = currentPage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;
  const currentItems = defaultersList.slice(indexOfFirstPost, indexOfLastPost);

  // Pagination
  const handlePagination = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const prevPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage !== Math.ceil(defaultersList.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <main className="w-full">
      <div className="bg-white rounded-md shadow-md pb-6">
        <div
          className={`w-full overflow-x-auto ${
            isLoading && "animate-pulse h-[50vh]"
          }`}
        >
          <div
            className="flex items-center justify-between p-6"
            style={{ minWidth: "700px" }}
          >
            <div className="flex justify-between w-full">
              <h1 className="text-base font-semibold ">Defaulters List</h1>
              <div className="relative md:w-[30rem] w-fit">
                <IoSearchOutline className="w-6 h-6 absolute top-[0.6rem] left-2 text-gray-300" />
                <input
                  type="search"
                  name="searchedUser"
                  id="searchedUser"
                  value={searchedUser}
                  onChange={(e) => searchForUsersWithEmail(e.target.value)}
                  placeholder="Search user using email"
                  className="border p-2 rounded-md indent-7 w-full"
                  disabled={defaultersList.length === 0}
                />
              </div>

              <div>
                <label htmlFor="filterOccupation">
                  Sort by:
                  <select
                    name="filterOccupation"
                    id="filterOccupation"
                    className="border p-2 ml-4 text-sm"
                    onChange={(e) => setFilter(e.target.value)}
                  >
                    <option value="all">All</option>
                    <option value="employed">Employed</option>
                    <option value="self-employed">Self-employed</option>
                    <option value="student">Students</option>
                  </select>
                </label>
              </div>
            </div>
          </div>
          <section className="overflow-x-auto">
            <table className="md:w-[1350px] w-[700px] my-3 text-nowrap">
              <thead className="bg-gray-50 font-bold text-left p-4">
                <tr>
                  <th className="p-2 ">S/N</th>
                  <th className="p-2 ">Full name</th>
                  <th className="p-2">Email</th>
                  <th className="p-2">Phone number</th>
                  <th className="p-2">Action</th>
                  <th className="p-2">BVN</th>
                  <th className="p-2">Loan Date</th>
                  <th className="p-2">Amount collected</th>
                  <th className="p-2">Amount paid</th>
                  <th className="p-2">Defaulted amount</th>
                  <th className="p-2">Installment default</th>
                  <th className="p-2">Overdue amount</th>
                  {adminLevel === "superadmin" && (
                    <th className="p-2">Action</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {searchedUser.length > 0 ? (
                  searchResults && searchResults.length > 0 ? (
                    searchResults.map((data: any, index: number) => (
                      <tr className="border-b border-gray-300" key={index}>
                        <td className="text-secondary py-4 px-2">
                          {index + 1}
                        </td>
                        <td className="py-4 px-2">
                          {data.firstName + " " + data.lastName}
                        </td>
                        <td className="py-4 px-2">{data.email}</td>
                        <td className="py-4 px-2">{data.phoneNumber}</td>
                        <td className="py-4 px-2">
                          <button
                            disabled={
                              data.cards === "No cards attached to this user"
                            }
                            onClick={() =>
                              handleCardDebit(data.userId, data.defaultAmount)
                            }
                            className={`${
                              data.cards === "No cards attached to this user"
                                ? "bg-gray-200 text-black"
                                : "bg-secondary text-white"
                            } p-2 text-sm rounded-md`}
                          >
                            {data.cards === "No cards attached to this user"
                              ? "No Card"
                              : "Card debit"}
                          </button>
                        </td>
                        <td className="py-4 px-2">
                          <button
                            onClick={() => handleBvnClick(data.userId)}
                            className="text-blue-500 hover:underline focus:outline-none"
                          >
                            {data.bvn}
                          </button>
                        </td>
                        <td className="py-4 px-2">
                          {new Date(data.loanInitialDate).toLocaleDateString(
                            "en-US",
                            {
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                            }
                          )}
                        </td>
                        <td className="py-4 px-2">
                          ₦{formatNumber(data.amountCollected)}
                        </td>
                        <td className="py-4 px-2">
                          ₦{formatNumber(data.amountPaid)}
                        </td>
                        <td className="py-4 px-2">
                          ₦{formatNumber(data.defaultAmount)}
                        </td>
                        <td className="py-4 px-2">{data.InstallmentDefault}</td>
                        <td className="py-4 px-2">
                          ₦{formatNumber(data.OverdueAmount)}
                        </td>
                        {adminLevel === "superadmin" && (
                          <td className="py-4 px-2">
                            <button
                              type="button"
                              className="bg-secondary text-white text-sm rounded-md p-2"
                              onClick={() =>
                                handleEmandate(
                                  data.monoId,
                                  data.defaultAmount,
                                  index
                                )
                              }
                            >
                              {isLoadingEmandate[index] ? (
                                <Preloader />
                              ) : (
                                "e-mandate debit"
                              )}
                            </button>
                          </td>
                        )}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan={10}
                        className="text-center text-secondary p-3"
                      >
                        User with email not found
                      </td>
                    </tr>
                  )
                ) : defaultersList && defaultersList.length > 0 ? (
                  currentItems
                    .filter((data: any) => {
                      if (filter === "employed") {
                        return data.employmentStatus === "employed";
                      } else if (filter === "self-employed") {
                        return data.employmentStatus === "selfEmployed";
                      } else if (filter === "student") {
                        return data.employmentStatus === "student";
                      } else if (filter === "all") {
                        return data.employmentStatus;
                      }
                      return true;
                    })
                    .filter((data: any) => data.OverdueAmount > 0)
                    .map((data: any, index: any) => (
                      <tr className="border-b border-gray-300" key={index}>
                        <td className="text-secondary py-4 px-2">
                          {index + indexOfFirstPost + 1}
                        </td>
                        <td className="py-4 px-2">
                          {data.firstName + " " + data.lastName}
                        </td>
                        <td className="py-4 px-2">{data.email}</td>
                        <td className="py-4 px-2">{data.phoneNumber}</td>
                        <td className="py-4 px-2">
                          <button
                            disabled={
                              data.cards === "No cards attached to this user"
                            }
                            onClick={() =>
                              handleCardDebit(data.userId, data.defaultAmount)
                            }
                            className={`${
                              data.cards === "No cards attached to this user"
                                ? "bg-gray-200 text-black"
                                : "bg-secondary text-white"
                            } p-2 text-sm rounded-md`}
                          >
                            {data.cards === "No cards attached to this user"
                              ? "No Card"
                              : "Card debit"}
                          </button>
                        </td>
                        <td className="py-4 px-2">
                          <button
                            onClick={() => handleBvnClick(data.userId)}
                            className="text-blue-500 hover:underline focus:outline-none"
                          >
                            {data.bvn}
                          </button>
                        </td>
                        <td className="py-4 px-2">
                          {new Date(data.loanInitialDate).toLocaleDateString(
                            "en-US",
                            {
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                            }
                          )}
                        </td>
                        <td className="py-4 px-2">
                          ₦{formatNumber(data.amountCollected)}
                        </td>
                        <td className="py-4 px-2">
                          ₦{formatNumber(data.amountPaid)}
                        </td>
                        <td className="py-4 px-2">
                          ₦{formatNumber(data.defaultAmount)}
                        </td>
                        <td className="py-4 px-2">{data.InstallmentDefault}</td>
                        <td className="py-4 px-2">
                          ₦{formatNumber(data.OverdueAmount)}
                        </td>
                        {adminLevel === "superadmin" && (
                          <td className="py-4 px-2">
                            <button
                              type="button"
                              className="bg-secondary text-white text-sm rounded-md p-2 w-40"
                              onClick={() =>
                                handleEmandate(
                                  data.monoId,
                                  data.defaultAmount,
                                  index
                                )
                              }
                            >
                              {isLoadingEmandate[index] ? (
                                <Preloader />
                              ) : (
                                "e-mandate debit"
                              )}
                            </button>
                          </td>
                        )}
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td colSpan={10} className="text-secondary text-center p-5">
                      No Defaulter Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <section className="p-3 my-5">
              <Pagination
                length={
                  searchResults.length > 0
                    ? searchResults.length
                    : defaultersList.filter(
                        (data: any) => data.OverdueAmount > 0
                      ).length
                }
                itemsPerPage={itemsPerPage}
                handlePagination={handlePagination}
                currentPage={currentPage}
                prevPage={prevPage}
                nextPage={nextPage}
              />
            </section>
          </section>
        </div>
      </div>
      {emandateModal && (
        <EMandateModal
          setEMandateModal={setEmandateModal}
          defaultAmount={defaultAmount}
          acctClick={acctClick}
        />
      )}
      {bvnModalOpen && (
        <BVNVerificationModal
          setBvnModalOpen={setBvnModalOpen}
          selectedBvn={selectedBvn}
          acctClick={acctClick}
        />
      )}
      {cardDebitModal && (
        <CardDebit
          defaultAmount={defaultAmount}
          allCards={allCards}
          setCardDebitModal={setCardDebitModal}
        />
      )}
    </main>
  );
};
