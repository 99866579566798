import AdminDetails from "../components/AdminDetails";
import DashboardLanding from "./Dietitian/DashboardLanding";
const Dashboard = () => {
  const role = "dietitia";

  return (
    <div className="flex w-full">
      <div className="py-1 px-4 lg:px-6 2xl:px-8 pb-8 w-full">
        {role === "dietitian".toLowerCase() ? (
          <DashboardLanding />
        ) : (
          <AdminDetails />
        )}
      </div>
    </div>
  );
};

export default Dashboard;
