import { NavbarProps } from "../types/types";
import { HiOutlineMenu } from "react-icons/hi";
import { IoIosNotificationsOutline, IoIosSearch } from "react-icons/io";
import { TfiClose } from "react-icons/tfi";
import { Link } from "react-router-dom";

const Navbar = ({ toggleTabNavigation, tabNavigation }: NavbarProps) => {
  const auth = sessionStorage.getItem("authState")
  const authState = auth && JSON.parse(auth)
  return (
    <div className="flex fixed z-30 gap-3 w-full lg:w-[80%] items-center justify-between bg-white py-4 px-4 lg:px-8">
      <div className="flex lg:hidden items-center gap-3 w-fit">
        <figure className="w-14">
          <img src="/assets/logo.png" alt="logo" />
        </figure>
        <div onClick={toggleTabNavigation} className="text-3xl lg:hidden">
          {!tabNavigation ? <HiOutlineMenu /> : <TfiClose />}
        </div>
      </div>

      <section className="w-full">
        <input
          type="search"
          name="search"
          id="search"
          placeholder="Search"
          className="bg-[#F7F8FA] p-3 w-full text-sm md:block hidden"
        />
      </section>
      <section className="flex gap-3 items-center">
        <div className="flex items-center">
          <IoIosSearch className="w-8 h-8 md:hidden block" />
          <button type="button">
            <Link to={"notifications"}>
              <IoIosNotificationsOutline className="w-8 h-8" />
            </Link>
          </button>
        </div>
        <div className="flex items-center">
          <figure className="w-12 h-12 rounded-md">
            <img src="/assets/Avatar.png" alt="avatar" />
          </figure>
          <div className="p-3 text-sm ">
            <p className="font-bold">{authState.firstName}</p>
            <p className="text-[#93A3AB]">{authState.adminLevel}</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Navbar;
