import axios from "axios";
import { useEffect, useState } from "react";
import { EMandateAccts } from "../../components/modals/EmandateAccts";

import { RootState } from "../../redux/store";
import { useAppSelector } from "../../redux/hooks";
import { Preloader } from "../../components/elements/Preloader";
import { useNavigate } from "react-router-dom";
import Pagination from "../../components/Pagination";
import { IoSearchOutline } from "react-icons/io5";

export const Emandate = () => {
  const { token } = useAppSelector((store: RootState) => store.auth);
  const [allEmandate, setAllEmandate] = useState<[]>([]);
  const [showEmandateModal, setShowEmandateModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchedUser, setSearchedUser] = useState<any>([]);
  const [searchResults, setSearchResults] = useState([]);
  const [acctNameClick, setAcctNameClick] = useState({
    acctName: "",
    accounts: [],
  });

  const navigate = useNavigate();

  useEffect(() => {
    const fetchMandates = async () => {
      setIsLoading(true);
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/getActiveMandates
          `,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        setAllEmandate(res.data);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchMandates();
  }, []);

  const filteredEmandate = allEmandate.filter(
    (account: any, index: number, self: any) =>
      index ===
      self.findIndex((t: any) => t.account_name === account.account_name)
  );

  const handleViewMore = (acctName: string) => {
    setAcctNameClick((prev) => ({
      ...prev,
      acctName: acctName,
    }));

    const acctArray = allEmandate.filter(
      (acct: any) => acct.account_name === acctName
    );
    setAcctNameClick((prev) => ({
      ...prev,
      accounts: acctArray,
    }));

    setShowEmandateModal((prev) => !prev);
  };

  const [itemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastPost = currentPage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;
  const currentItems = filteredEmandate.slice(
    indexOfFirstPost,
    indexOfLastPost
  );

  // Pagination
  const handlePagination = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const prevPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage !== Math.ceil(filteredEmandate.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const searchForUsersWithAccountName = (value: string) => {
    setSearchedUser(value);
    if (value.length > 0) {
      const searchResult =
        allEmandate?.filter((user: any) =>
          user.account_name?.toLowerCase().includes(value.toLowerCase())
        ) || null;
      setSearchResults(searchResult);
    } else {
      setSearchResults([]);
    }
  };

  return (
    <main>
      <div className="bg-white p-3 mt-3 rounded-md flex items-center justify-between">
        <h1 className="font-bold text-lg">All E-Mandate List</h1>
        <div className="relative md:w-[30rem] w-fit">
          <IoSearchOutline className="w-6 h-6 absolute top-[0.6rem] left-2 text-gray-300" />
          <input
            type="search"
            name="searchedUser"
            id="searchedUser"
            value={searchedUser}
            onChange={(e) => searchForUsersWithAccountName(e.target.value)}
            placeholder="Search user using account name"
            className="border p-2 rounded-md indent-7 w-full"
            disabled={allEmandate.length === 0}
          />
        </div>
      </div>
      <section>
        <table className="w-full my-3">
          <thead>
            <tr className="bg-gray-50 font-bold md:text-base text-sm h-12 text-left">
              <th className="pl-3">SN</th>
              <th className="pl-3">Client Account Name</th>
              <th className="pl-3"></th>
            </tr>
          </thead>
          <tbody className="md:text-base text-sm">
            {isLoading ? (
              <tr>
                <td colSpan={3} className="text-center p-5">
                  <Preloader />
                </td>
              </tr>
            ) : searchedUser.length > 0 ? (
              searchResults && searchResults.length > 0 ? (
                searchResults.map((item: any, index: number) => (
                  <tr key={index} className="border-b-2">
                    <td className="p-3">{index + indexOfFirstPost + 1}</td>
                    <td className="p-3">{item.account_name}</td>
                    <td className="p-3">
                      <button
                        type="button"
                        className="bg-secondary text-white p-2 rounded-md text-sm"
                        onClick={() => handleViewMore(item.account_name)}
                      >
                        View More
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={10} className="text-center text-secondary p-3">
                    User with account name not found
                  </td>
                </tr>
              )
            ) : !isLoading && filteredEmandate.length > 0 ? (
              currentItems.map((item: any, index: number) => (
                <tr key={index} className="border-b-2">
                  <td className="p-3">{index + indexOfFirstPost + 1}</td>
                  <td className="p-3">{item.account_name}</td>
                  <td className="p-3">
                    <button
                      type="button"
                      className="bg-secondary text-white p-2 rounded-md text-sm"
                      onClick={() => handleViewMore(item.account_name)}
                    >
                      View More
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={3} className="text-center text-secondary p-5">
                  No Mandates found!
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <section className="p-3 my-5">
          <Pagination
            length={
              searchResults.length > 0
                ? searchResults.length
                : allEmandate.length
            }
            itemsPerPage={itemsPerPage}
            handlePagination={handlePagination}
            currentPage={currentPage}
            prevPage={prevPage}
            nextPage={nextPage}
          />
        </section>
      </section>
      {showEmandateModal && (
        <EMandateAccts
          setShowEmandateModal={setShowEmandateModal}
          acctNameClick={acctNameClick}
        />
      )}
    </main>
  );
};
