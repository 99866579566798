import { useState, useEffect } from "react";
import axios from "axios";
import { useAppSelector } from "../redux/hooks";
import { RootState } from "../redux/store";
import { formatNumber } from "../redux/thunk";
import { Link } from "react-router-dom";

const AdminDetails = () => {
  const { token } = useAppSelector((store: RootState) => store.auth);
  const [stats, setStats] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    getDashboardStats();
  }, []);

  const getDashboardStats = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/getDashboardStatistic`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setStats(response.data);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      setStats({});
    }
  };

  return (
    <main>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-5 mb-8 mt-[1.5rem]">
        <div
          className={`bg-white p-5 rounded-md border border-green-300 shadow-md w-[100%] ${
            isLoading && "animate-pulse "
          }`}
        >
          <h1 className="text-sm font-medium pb-4 text-gray-700 capitalize">
            Verified Users
          </h1>
          {/* <h1 className="font-semibold text-3xl">{isLoading ? "0" : stats.verifiedCustomersCount}</h1> */}
          <h1 className="font-semibold text-3xl break-words">
            {isLoading ? "0" : formatNumber(stats.verifiedCustomersCount)}
          </h1>
        </div>
        <div
          className={`bg-white p-5 rounded-md border border-green-300 shadow-md w-[100%] ${
            isLoading && "animate-pulse "
          }`}
        >
          <h1 className="text-sm font-medium pb-4 text-gray-700 caption-bottom">
            Total Students
          </h1>
          <h1 className="font-semibold text-3xl break-words">
            {isLoading ? "0" : formatNumber(stats.studentCustomersCount)}
          </h1>
        </div>
        <div
          className={`bg-white p-5 rounded-md border border-green-300 shadow-md w-[100%] ${
            isLoading && "animate-pulse "
          }`}
        >
          <h1 className="text-sm font-medium pb-4 text-gray-700 capitalize">
            Total Employed
          </h1>
          <h1 className="font-semibold text-3xl break-words">
            {isLoading ? "0" : formatNumber(stats.employedCustomersCount)}
          </h1>
        </div>
        <div
          className={`bg-white p-5 rounded-md border border-green-300 shadow-md w-[100%] ${
            isLoading && "animate-pulse "
          }`}
        >
          <h1 className="text-sm font-medium pb-4 text-gray-700">
            Total Self-Employed
          </h1>
          <h1 className="font-semibold text-3xl break-words">
            {isLoading ? "0" : formatNumber(stats.selfEmployedCustomersCount)}
          </h1>
        </div>
        <div
          className={`bg-white p-5 rounded-md border border-green-300 shadow-md w-[100%] ${
            isLoading && "animate-pulse "
          }`}
        >
          <h1 className="text-sm font-medium pb-4 text-gray-700 capitalize">
            Total Orders
          </h1>
          <h1 className="font-semibold text-3xl break-words">
            {isLoading ? "0" : formatNumber(stats.totalOrdersCount)}
          </h1>
        </div>
        <div
          className={`bg-white p-5 rounded-md border border-green-300 shadow-md w-[100%] ${
            isLoading && "animate-pulse "
          }`}
        >
          <h1 className="text-sm font-medium pb-4 text-gray-700 capitalize">
            Total Loan
          </h1>
          <h1 className="font-semibold text-3xl break-words">
            {isLoading
              ? "0"
              : stats.totalLoans &&
                formatNumber(Math.round(stats.totalLoans.toFixed(2)))}
          </h1>
        </div>
        <div
          className={`bg-white p-5 rounded-md border border-green-300 shadow-md w-[100%] ${
            isLoading && "animate-pulse "
          }`}
        >
          <h1 className="text-sm font-medium pb-4 text-gray-700 capitalize">
            Total paid
          </h1>
          <h1 className="font-semibold text-3xl break-words">
            {isLoading
              ? "0"
              : formatNumber(Math.round(stats.totalPaidLoans?.toFixed(2)))}
          </h1>
        </div>
        <div
          className={`bg-white p-5 rounded-md border border-green-300 shadow-md w-[100%] ${
            isLoading && "animate-pulse "
          }`}
        >
          <h1 className="text-sm font-medium pb-4 text-gray-700 capitalize">
            Total bad loan
          </h1>
          <h1 className="font-semibold text-3xl break-words">₦0</h1>
        </div>
        <div
          className={`bg-white p-5 rounded-md border border-green-300 shadow-md w-[100%] ${
            isLoading && "animate-pulse "
          }`}
        >
          <h1 className="text-sm font-medium pb-4 text-gray-700 capitalize">
            Expected for this month
          </h1>
          <h1 className="font-semibold text-3xl break-words">
            {isLoading
              ? "0"
              : stats.totalExpectedPayments &&
                formatNumber(stats.totalExpectedPayments.toFixed(2))}
          </h1>
        </div>
        <div
          className={`bg-white p-5 rounded-md border border-green-300 shadow-md w-[100%] ${
            isLoading && "animate-pulse "
          }`}
        >
          <h1 className="text-sm font-medium pb-4 text-gray-700 capitalize">
            Expected for this week
          </h1>
          <h1 className="font-semibold text-3xl break-words">
            {isLoading
              ? "0.00"
              : stats.totalExpectedPayments &&
                formatNumber(stats.totalExpectedPayments.toFixed(2))}
          </h1>
        </div>
        <div
          className={`bg-white p-5 rounded-md border border-green-300 shadow-md w-[100%] ${
            isLoading && "animate-pulse "
          }`}
        >
          <h1 className="text-sm font-medium pb-4 text-gray-700 capitalize">
            Expected today
          </h1>
          <h1 className="font-semibold text-3xl break-words">
            {isLoading && "0"}
          </h1>
        </div>
        <div
          className={`bg-white p-5 rounded-md border border-green-300 shadow-md w-[100%] ${
            isLoading && "animate-pulse "
          }`}
        >
          <h1 className="text-sm font-medium pb-4 text-gray-700 capitalize">
            faqs count
          </h1>
          <h1 className="font-semibold text-3xl break-words">
            {isLoading ? "0" : formatNumber(stats.faqsCount)}
          </h1>
        </div>
        <div
          className={`bg-white p-5 rounded-md border border-green-300 shadow-md w-[100%] ${
            isLoading && "animate-pulse "
          }`}
        >
          <h1 className="text-sm font-medium pb-4 text-gray-700 capitalize">
            Total disapprovals
          </h1>
          <h1 className="font-semibold text-3xl break-words">
            {isLoading ? "0" : formatNumber(stats.totalDisapprovedOrdersCount)}
          </h1>
        </div>
        <div
          className={`bg-white p-5 rounded-md border border-green-300 shadow-md w-[100%] ${
            isLoading && "animate-pulse "
          }`}
        >
          <h1 className="text-sm font-medium pb-4 text-gray-700 capitalize">
            Total approved
          </h1>
          <h1 className="font-semibold text-3xl break-words">
            {isLoading ? "0" : formatNumber(stats.totalApprovedOrdersCount)}
          </h1>
        </div>
      </div>
      <p className="text-secondary font-semibold underline text-right cursor-pointer">
        <Link to={"analytics"}>View Analytics</Link>
      </p>
    </main>
  );
};

export default AdminDetails;
