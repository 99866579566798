// import {
//   Chart as ChartJs,
//   Tooltip,
//   Legend,
//   BarElement,
//   ArcElement,
//   ChartOptions,
//   LinearScale,
//   CategoryScale,
// } from "chart.js";
// import { Pie, Bar } from "react-chartjs-2";

import BarChart from "./BarChart";
import PieChart from "./PieChart";

// ChartJs.register(
//   Tooltip,
//   Legend,
//   ArcElement,
//   BarElement,
//   LinearScale,
//   CategoryScale
// );

const Analytics = () => {
  // const pieChartOptions: ChartOptions<"pie"> = {
  //   responsive: true,
  //   plugins: {
  //     legend: {
  //       position: "bottom",
  //       labels: {
  //         boxWidth: 20,
  //         boxHeight: 20,
  //       },
  //     },
  //   },
  // };

  // const barChartOptions: ChartOptions<"bar"> = {
  //   responsive: true,
  //   indexAxis: "y",
  //   plugins: {
  //     legend: {
  //       position: "bottom",
  //       labels: {
  //         boxWidth: 20,
  //         boxHeight: 20,
  //       },
  //     },
  //   },
  //   scales: {
  //     x: {
  //       min: 0,
  //       max: 100,
  //       beginAtZero: true,
  //       ticks: {
  //         stepSize: 10,
  //       },
  //     },
  //     y: {
  //       type: "category",
  //     },
  //   },
  // };

  return (
    <section className="flex flex-col md:flex-row justify-between gap-10">
      <section className="md:w-1/2 bg-white shadow-md p-5 rounded-b-lg">
        <div className="flex justify-between items-center mb-10 px-10">
          <h3 className="font-semibold text-lg">VERIFIED USERS</h3>
          <p className="font-medium">This Week</p>
        </div>
        <section className="flex items-end px-2 relative mb-5">
          <p className="border-b-2 font-bold border-red-500 block w-fit">Pie</p>
          <img
            src="/assets/line.svg"
            alt=""
            className="absolute -bottom-1 right-0 w-11/12"
          />
        </section>
        <section className="h-full">
          <PieChart />
        </section>
      </section>

      <section className="md:w-1/2  bg-white shadow-md p-5 rounded-b-lg">
        <div className="flex justify-between items-center mb-10 px-10">
          <h3 className="font-semibold text-lg">REQUEST APPROVAL</h3>
          <p className="font-medium">This Month</p>
        </div>
        <section className="flex items-end px-2 relative mb-5">
          <p className="border-b-2 font-bold border-red-500 block w-fit">Bar</p>
          <img
            src="/assets/line.svg"
            alt=""
            className="absolute -bottom-1 right-0 w-11/12"
          />
        </section>
        <section className="h-full">
          <BarChart />
        </section>
      </section>
    </section>
  );
};

export default Analytics;
