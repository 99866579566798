import axios from "axios";
import toast from "react-hot-toast";
import { useAppSelector } from "../../redux/hooks";
import { useState } from "react";
import { Preloader } from "../elements/Preloader";

const PackageModal = ({
  setViewPackage,
  id,
  selectedPackage,
  refreshPage,
}: any) => {
  const { token } = useAppSelector((store) => store.auth);
  const [isLoading, setIsLoading] = useState(false);

  const getAccuratePackageData = Array.isArray(selectedPackage)
    ? selectedPackage[0]
    : selectedPackage; // solve endpoint issue that returns an array first before an object

  const deletePackage = async () => {
    setIsLoading(true);
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_API_URL}/packages/${id}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      toast.success(res.data.message);
    } catch (error: any) {
      console.error(error);
      toast.error(error.message || "Deletion failed!");
    } finally {
      setIsLoading(false);
      setViewPackage(false);
      refreshPage();
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
      <div className="bg-white p-4 rounded-md" style={{ width: "500px" }}>
        <div className="flex justify-between  flex-col text-dark p-2 rounded-t-md mb-4">
          <div className="relative w-full">
            <button
              onClick={() => setViewPackage(false)}
              className="text-dark absolute right-0"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <section>
            <h3 className="font-semibold text-xl text-center capitalize">
              {getAccuratePackageData.name}
            </h3>

            <figure className="w-40 mx-auto my-2">
              <img
                className="w-full"
                src={
                  getAccuratePackageData.image && getAccuratePackageData.image
                }
                alt={getAccuratePackageData.name}
              />
            </figure>
            <section>
              <p className="font-semibold mb-2 text-sm">Products:</p>
              <table className="w-full text-left mb-3">
                <thead className="border bg-gray-50 text-sm">
                  <tr>
                    <th>Name</th>
                    <th>Measurement</th>
                    <th>Type</th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody>
                  {getAccuratePackageData.productIds?.map(
                    (product: any, index: number) => (
                      <tr key={index}>
                        <td className="p-2 border text-xs">{product.name}</td>
                        <td className="p-2 border text-xs">
                          {product.measurement}
                        </td>
                        <td className="p-2 border text-xs">{product.type}</td>
                        <td className="p-2 border text-xs">{product.price}</td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
              <p className="text-sm">
                <span className="font-semibold my-2">Package Price</span>: ₦
                {getAccuratePackageData.amount}
              </p>
              <p className="text-sm">
                <span className="font-semibold my-2">Description:</span>{" "}
                {getAccuratePackageData.description}
              </p>
              <p className="text-sm">
                <span className="font-semibold my-2">Created At: </span>
                {new Date(
                  getAccuratePackageData.createdAt
                ).toLocaleDateString()}
              </p>
            </section>

            <div className="flex items-center justify-center mt-3">
              <button
                type="button"
                className=" px-5 py-3 mr-3 rounded text-white bg-[#008b50]"
                onClick={deletePackage}
              >
                {isLoading ? <Preloader /> : "Delete this package"}
              </button>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default PackageModal;
