import { useEffect, useState } from "react";
import { Preloader } from "../../components/elements/Preloader";
import axios from "axios";
import { useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";
import toast from "react-hot-toast";
import { IoSearchOutline } from "react-icons/io5";
import Pagination from "../../components/Pagination";
import { Link } from "react-router-dom";

const PendingEmandates = () => {
  const { token } = useAppSelector((store: RootState) => store.auth);
  const [searchedUser, setSearchedUser] = useState<any>([]);
  const [searchResults, setSearchResults] = useState([]);

  const [isLoading, setIsLoading] = useState({
    pendingMandate: false,
    cancelMandate: false,
  });
  const [showCancelMandate, setShowCancelMandate] = useState({
    modal: false,
    username: "",
    id: "",
  });
  const [mandateData, setMandateData] = useState([]);

  const searchForUsersWithAccountName = (value: string) => {
    setSearchedUser(value);
    if (value.length > 0) {
      const searchResult =
        mandateData?.filter((user: any) =>
          user.account_name?.toLowerCase().includes(value.toLowerCase())
        ) || null;
      setSearchResults(searchResult);
    } else {
      setSearchResults([]);
    }
  };

  const getPendingMandates = async () => {
    setIsLoading((prev) => ({ ...prev, pendingMandate: true }));
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/getPendingMandates`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setMandateData(res.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading((prev) => ({ ...prev, pendingMandate: false }));
    }
  };

  useEffect(() => {
    getPendingMandates();
  }, []);

  const handleCancelMandate = async (id: string) => {
    setIsLoading((prev) => ({ ...prev, cancelMandate: true }));

    try {
      const res = await axios.patch(
        `https://api.withmono.com/v3/payments/mandates/${id}/cancel`,
        {},
        {
          headers: {
            Authorization: token,
            "mono-sec-key": `${process.env.REACT_APP_MONO_KEY}`,
          },
        }
      );
      toast.success(res.data.message);
      setShowCancelMandate((prev) => ({
        ...prev,
        modal: false,
        username: "",
        id: "",
      }));
      getPendingMandates();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading((prev) => ({ ...prev, cancelMandate: false }));
    }
  };

  const [itemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastPost = currentPage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;
  const currentItems = mandateData.slice(indexOfFirstPost, indexOfLastPost);

  // Pagination
  const handlePagination = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const prevPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage !== Math.ceil(mandateData.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <main>
      <div className="bg-white p-3 mt-3 rounded-md">
        <div className="flex justify-between items-center mb-3">
          <h1 className="font-bold text-lg">Pending Mandate List</h1>
          <button
            type="button"
            className="text-sm bg-secondary p-2 text-white rounded-md"
          >
            <Link to={"/dashboard/all-canceled-mandates"}>
              All canceled mandates
            </Link>
          </button>
        </div>
        <div className="relative">
          <IoSearchOutline className="w-6 h-6 absolute top-[0.6rem] left-2 text-gray-300 text-sm" />
          <input
            type="search"
            name="searchedUser"
            id="searchedUser"
            value={searchedUser}
            onChange={(e) => searchForUsersWithAccountName(e.target.value)}
            placeholder="Search user using account name"
            className="border p-2 rounded-md indent-7 w-full"
            disabled={mandateData.length === 0}
          />
        </div>
      </div>
      <section>
        <table className="w-full my-3">
          <thead>
            <tr className="bg-gray-50 font-bold md:text-base text-sm h-12 text-left">
              <th className="pl-3">SN</th>
              <th className="pl-3">Client Account Name</th>
              <th className="pl-3">Date</th>
              <th className="pl-3">Time</th>
              <th className="pl-3"></th>
            </tr>
          </thead>
          <tbody className="md:text-base text-sm">
            {isLoading.pendingMandate ? (
              <tr>
                <td colSpan={3} className="text-center p-5">
                  <Preloader />
                </td>
              </tr>
            ) : searchedUser.length > 0 ? (
              searchResults && searchResults.length > 0 ? (
                searchResults.map((item: any, index: number) => (
                  <tr key={index} className="border-b-2">
                    <td className="p-3">{index + 1}</td>
                    <td className="p-3">{item.account_name}</td>
                    <td className="p-3">
                      {new Date(item.date).toLocaleDateString()}
                    </td>
                    <td className="p-3">
                      {new Date(item.date).toLocaleTimeString()}
                    </td>
                    <td className="p-3">
                      <button
                        type="button"
                        className="bg-secondary text-white p-2 rounded-md text-sm"
                        onClick={() =>
                          setShowCancelMandate((prev) => ({
                            ...prev,
                            modal: true,
                            username: item.account_name,
                            id: item.id,
                          }))
                        }
                      >
                        Cancel Mandate
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={10} className="text-center text-secondary p-3">
                    User with email not found
                  </td>
                </tr>
              )
            ) : !isLoading.pendingMandate && mandateData.length > 0 ? (
              currentItems.map((item: any, index: number) => (
                <tr key={index} className="border-b-2">
                  <td className="p-3">{index + indexOfFirstPost + 1}</td>
                  <td className="p-3">{item.account_name}</td>
                  <td className="p-3">
                    {new Date(item.date).toLocaleDateString()}
                  </td>
                  <td className="p-3">
                    {new Date(item.date).toLocaleTimeString()}
                  </td>
                  <td className="p-3">
                    <button
                      type="button"
                      className="bg-secondary text-white p-2 rounded-md text-sm"
                      onClick={() =>
                        setShowCancelMandate((prev) => ({
                          ...prev,
                          modal: true,
                          username: item.account_name,
                          id: item.id,
                        }))
                      }
                    >
                      Cancel Mandate
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={3} className="text-center text-secondary p-5">
                  No Mandates found!
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <section className="p-3 my-5">
          <Pagination
            length={
              searchResults.length > 0
                ? searchResults.length
                : mandateData.length
            }
            itemsPerPage={itemsPerPage}
            handlePagination={handlePagination}
            currentPage={currentPage}
            prevPage={prevPage}
            nextPage={nextPage}
          />
        </section>
      </section>
      {showCancelMandate.modal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="bg-white p-4 rounded-md" style={{ width: "500px" }}>
            <div className="flex justify-between items-center flex-col text-dark p-2 rounded-t-md mb-4">
              <div className="relative w-full">
                <button
                  onClick={() =>
                    setShowCancelMandate((prev) => ({
                      ...prev,
                      modal: false,
                    }))
                  }
                  className="text-dark absolute right-0"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>

              <div>
                <h3 className=" m-3 font-semibold text-center capitalize my-5">
                  Cancel Mandate on {showCancelMandate.username.toLowerCase()}'s
                  Account?
                </h3>
                <div className="flex justify-center gap-3">
                  <button
                    type="button"
                    className={`w-28 py-2 rounded text-white bg-[#008b50] ${
                      isLoading.cancelMandate && "pb-[1.1rem]"
                    }`}
                    onClick={() => handleCancelMandate(showCancelMandate.id)}
                  >
                    {isLoading.cancelMandate ? <Preloader /> : "Yes"}
                  </button>
                  <button
                    type="button"
                    className="w-28 px-5 py-2 bg-red-500 rounded text-white "
                    onClick={() =>
                      setShowCancelMandate((prev) => ({
                        ...prev,
                        modal: false,
                      }))
                    }
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </main>
  );
};

export default PendingEmandates;
