import { IoIosMore, IoMdCheckmark } from "react-icons/io";

const NotificationCard = ({
  index,
  img,
  name,
  msg,
  details,
  dateTime,
  readStatus,
  setReadMsg,
  setUnreadMsg,
  activeNotificationId,
  setActiveNotificationId,
}: {
  index: number;
  img: string;
  name: string;
  msg: string;
  details: string;
  dateTime: string;
  readStatus: boolean;
  setReadMsg: () => void;
  setUnreadMsg: () => void;
  activeNotificationId: null | number;
  setActiveNotificationId: (index: number | null) => void;
}) => {
  const isActive = activeNotificationId === index;
  const handleOutsideClick = () => setActiveNotificationId(null);

  return (
    <section
      className={`${
        readStatus ? "bg-white" : "bg-gray-300"
      } my-1 flex items-center justify-between p-3 border-b relative`}
      onClick={handleOutsideClick}
    >
      <section
        className="flex items-center gap-5 cursor-pointer"
        onClick={setReadMsg}
      >
        <div className="flex items-center gap-3">
          {!readStatus && (
            <div className="w-2 h-2 rounded-[50%] bg-green-500"></div>
          )}
          <figure className="w-12 h-12">
            <img src={img} alt={name} className="w-full h-full rounded-[50%]" />
          </figure>
        </div>
        <div>
          <h5 className="font-medium text-sm">{msg}</h5>
          {details.length === 0 && (
            <button type="button" className="text-xs text-secondary">
              View
            </button>
          )}
          {details.length > 0 && (
            <p className="text-xs text-red-500 py-1 cursor-pointer px-2 border-l-4 mt-1">
              {details}
            </p>
          )}
        </div>
      </section>
      <section>
        <p className="text-sm">{dateTime}</p>
        <button
          type="button"
          onClick={(e) => {
            e.stopPropagation();
            setActiveNotificationId(isActive ? null : index);
          }}
        >
          <IoIosMore className="w-6 h-6" />
        </button>
      </section>
      {isActive && (
        <section className="p-2 shadow-md absolute bg-white right-10 z-10 rounded-md -bottom-24">
          <ul className="text-sm">
            <li className="p-2">
              {readStatus ? (
                <button
                  type="button"
                  className="flex items-center gap-3 w-full group"
                  onClick={() => {
                    setUnreadMsg();
                    setActiveNotificationId(null);
                  }}
                >
                  Mark as unread
                  <IoMdCheckmark className="text-gray-500 opacity-0 group-hover:opacity-100 group-hover:text-secondary transition-opacity duration-200" />
                </button>
              ) : (
                <button
                  type="button"
                  className="flex items-center gap-3 w-full group"
                  onClick={() => {
                    setReadMsg();
                    setActiveNotificationId(null);
                  }}
                >
                  Mark as read
                  <IoMdCheckmark className="text-gray-500 opacity-0 group-hover:opacity-100 group-hover:text-secondary transition-opacity duration-200" />
                </button>
              )}
            </li>
            <li className="p-2">Archive </li>
            <li className="p-2">Turn off notifications</li>
          </ul>
        </section>
      )}
    </section>
  );
};

export default NotificationCard;
